import React, { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
} from "@material-tailwind/react";
import { canadianProvinces } from "../../utils/constants";
import { isValidName, isValidPhoneNumber } from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import UserService from "../../services/user.service";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import { formatPhone } from "../../utils/helpers";
export default function PhoneInfoEditorForm({ loggedInUser, onComplete }) {
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(loggedInUser.phone);
  const [formattedPhone, setFormattedPhone] = useState(
    formatPhone(loggedInUser.phone, "")
  );
  const handleSubmit = () => {
    if (error || !phone || !isValidPhoneNumber(phone)) {
      setAlert("NotComplete");
    } else {
      setAlert("");
      setLoading(true);
      UserService.updateUserDetails({
        phone: phone,
        id: loggedInUser.id,
      }).then(
        () => {
          setLoading(false);
          onComplete({ type: "Phone", result: "success" });
        },
        (error) => {
          setLoading(false);
          onComplete({ type: "Phone", result: "fail" });
        }
      );
    }
  };
  const onChange = (e) => {
    setPhone(e.target.value);
    setFormattedPhone(formatPhone(e.target.value, ""));
  };
  return (
    <>
      <Card className="mx-auto w-full max-w-[24rem] md:p-2">
        {!loading && (
          <>
            <CardBody className="flex flex-col gap-6">
              {alert && alert == "fail" && (
                <AlertCustomStyles
                  status={alert}
                  message={"Updating address failed, try again!"}
                />
              )}
              {alert && alert == "NotComplete" && (
                <AlertCustomStyles status={alert} message={"Invalid phone"} />
              )}
              {/* <Typography
                className="mb-1 font-normal font-nunito"
                variant="paragraph"
                color="gray"
              >
                Change Phone
              </Typography> */}
              <Input
                name="phone"
                label="Change Phone"
                onChange={onChange}
                error={error}
                value={formattedPhone}
              />
            </CardBody>
            <CardFooter className="pt-0 flex gap-3 m-auto">
              <Button
                variant="button"
                className=" bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
                onClick={handleSubmit}
                size="sm"
              >
                Update
              </Button>
              <Button
                variant="outlined"
                color="red"
                onClick={() => onComplete({ type: "Phone", result: null })}
                size="sm"
              >
                <span>Cancel</span>
              </Button>
            </CardFooter>
          </>
        )}
        {loading && (
          <div className="p-20">
            <SpinnerWithInfo message="Saving changes..." />
          </div>
        )}
      </Card>
    </>
  );
}
