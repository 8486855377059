import { Alert } from "@material-tailwind/react";

function IconSuccess() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function IconFail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );
}

export function AlertCustomStyles({ status, message }) {
  return (
    <>
      <Alert
        icon={status == "success" ? <IconSuccess /> : <IconFail />}
        className={`${
          status == "success"
            ? "rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 md:font-medium font-small   text-[#2ec946]"
            : "rounded-none border-l-4 border-[#f44336] bg-[#f44336]/10 md:font-medium font-small text-[#f44336]"
        } px-1 py-1 md:px-4 md:py-4`}
        AlertProps={{
          className: " mr-0",
        }}
      >
        {message}
      </Alert>
    </>
  );
}
