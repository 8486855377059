import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import React, { useState } from "react";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  isValidPassword,
} from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { formatPhone, getDays, getYears } from "../../utils/helpers";
import { getMonths } from "../../utils/constants";
import UserService from "../../services/user.service";
const RegisterStepOne = ({ onStepOneComplete, firstStepData }) => {
  const [values, setValues] = useState({
    firstName: firstStepData.firstName || "",
    lastName: firstStepData.lastName || "",
    email: firstStepData.email || "",
    phoneNumber: firstStepData.phoneNumber || "",
    password: firstStepData.password || "",
    day: firstStepData.day || "",
    month: firstStepData.month || "",
    year: firstStepData.year || "",
  });
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const [size, setSize] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsNotChecked, setTermsNotChecked] = useState(true);
  let [errors, setErrors] = useState({
    firstName: 0,
    lastName: 0,
    email: 0,
    phoneNumber: 0,
    password: 0,
    day: 0,
    month: 0,
    year: 0,
  });
  const inputs = [
    {
      id: 0,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Email address is invalid!",
      label: "Email",
      required: true,
    },

    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "firstName",
      errorMessage: "First Name is required!",
      label: "First Name",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      type: "text",
      placeholder: "lastName",
      errorMessage: "Last Name is required!",
      label: "Last Name",
      required: true,
    },

    {
      id: 3,
      name: "phoneNumber",
      type: "number",
      placeholder: "Phone Number",
      errorMessage: "Phone number is invalid!",
      label: "Phone Number",
      required: true,
    },

    {
      id: 4,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage: "Password should be 8-20 characters!",
      label: "Password",
      pattern: `^[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];
  const handleSubmit = async (e) => {
    if (
      Object.values(errors).every((v) => v === 0) &&
      !Object.values(values).some((x) => x == "") &&
      termsAndConditions
    ) {
      const exist = await UserService.userExist({ email: values["email"] });
      if (!exist.exist) {
        onStepOneComplete(values);
      } else {
        setMsg("Email already registered. Go to login");
        setAlert("fail");
      }
    } else {
      if (!termsAndConditions) setTermsNotChecked(false);
      if (!values["day"]) errors["day"] = 1;
      if (!values["month"]) errors["month"] = 1;
      if (!values["year"]) errors["year"] = 1;
      setMsg("Invalid Information");
      setAlert("fail");
    }
  };
  const handleOpen = (value) => setSize(value);

  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.name == "phoneNumber"
          ? e.target.value.replace(/\D/g, "")
          : e.target.value,
    });
  };
  const handleValidation = (e) => {
    if (e.target.name == "firstName")
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "lastName")
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "email") {
      !isValidEmail(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    }
    if (e.target.name == "phoneNumber")
      !isValidPhoneNumber(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "password")
      !isValidPassword(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
  };
  const setDayChange = (e) => {
    errors["day"] = 0;
    setValues({ ...values, day: e });
  };
  const setMonthChange = (e) => {
    errors["month"] = 0;
    setValues({ ...values, month: e });
  };
  const setYearChange = (e) => {
    errors["year"] = 0;
    setValues({ ...values, year: e });
  };
  return (
    <div>
      <div class="mx-auto max-w-screen-md max-h md:py-12 py-4">
        <div class="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto sm:max-w-[70%] max-w-[90%]">
          <h4 class="block font-nunito text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
            Create Your Account
          </h4>
          <p class="block mt-1 font-nunito text-base antialiased font-normal leading-relaxed text-gray-700">
            Nice to meet you! Enter your details to register.
          </p>
          <form class="max-w-screen-lg mt-8 mb-2 w-80 sm:w-96 flex flex-col md:gap-6 gap-5">
            {alert && <AlertCustomStyles status={alert} message={msg} />}
            {inputs.map((input) => (
              <div class="relative h-22 md:w-full">
                <Input
                  label={
                    input.name == "password"
                      ? "Password (at least 8 characters)"
                      : input.label
                  }
                  type={
                    input.name == "password"
                      ? "password"
                      : input.name == "phoneNumber"
                      ? "tel"
                      : input.name == "email"
                      ? "email"
                      : "text"
                  }
                  onChange={onChange}
                  value={
                    input.name == "phoneNumber"
                      ? formatPhone(values[input.name], "Canada")
                      : values[input.name]
                  }
                  onBlur={handleValidation}
                  name={input.name}
                  error={errors[input.name]}
                  containerProps={{
                    className: "md:min-h-[45px] min-h-[20px] md:text-[16px]",
                  }}
                  labelProps={{
                    className: "text-[14px]",
                  }}
                  maxLength={input.name == "phoneNumber" ? 10 : ""}
                  /*  class="peer h-full w-full font-nunito rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" */
                />
              </div>
            ))}
            <div>Date of birth</div>
            <div className="w-60 flex md:gap-3 gap-1">
              <Select
                label="Day"
                name="day"
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none ",
                  })
                }
                value={values["day"]}
                onChange={setDayChange}
                containerProps={{ className: "md:min-w-[100px] min-w-[90px]" }}
                error={errors["day"]}
              >
                {getDays().map((day) => (
                  <Option
                    key={day}
                    value={day}
                    className="flex items-center gap-2"
                  >
                    {day}
                  </Option>
                ))}
              </Select>
              <Select
                label="Month"
                name="month"
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                  })
                }
                value={values["month"]}
                onChange={setMonthChange}
                containerProps={{
                  className: "md:!min-w-[130px] !min-w-[110px] !max-w-[110px]",
                }}
                error={errors["month"]}
              >
                {getMonths.map((month) => (
                  <Option
                    key={month}
                    value={month}
                    className="flex items-center gap-2"
                  >
                    {month}
                  </Option>
                ))}
              </Select>
              <Select
                label="Year"
                name="year"
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none max-w-20",
                  })
                }
                value={values["year"]}
                onChange={setYearChange}
                containerProps={{
                  className: "md:min-w-[120px] !min-w-[110px]",
                }}
                error={errors["year"]}
              >
                {getYears().map((year) => (
                  <Option
                    key={year}
                    value={year}
                    className="flex items-center gap-2"
                  >
                    {year}
                  </Option>
                ))}
              </Select>
            </div>
            <div class="inline-flex items-center">
              <label
                class="relative -ml-2.5 flex cursor-pointer items-center rounded-full p-3"
                htmlFor="remember"
              >
                <input
                  type="checkbox"
                  onClick={() => setTermsAndConditions(!termsAndConditions)}
                  class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                  id="remember"
                />
                <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </label>
              <label
                class="mt-px font-light text-gray-700 cursor-pointer select-none"
                htmlFor="remember"
              >
                <p class="flex items-center font-nunito text-sm antialiased font-normal leading-normal text-gray-700">
                  I agree the
                  <a
                    href="/terms-and-conditions"
                    target="_blank"
                    class="font-medium transition-colors text-blue-500 hover:text-blue-300"
                  >
                    &nbsp;Terms and Conditions
                  </a>
                </p>
              </label>
            </div>
            {!termsNotChecked && (
              <div class="text-[12px] ml-2 text-[#e53935] ">
                Please agree the terms and conditions
              </div>
            )}
            <button
              onClick={handleSubmit}
              /* disabled={
                !isValidEmail(values.Email) ||
                !isValidPassword(values.Password) ||
                !isValidName(values.name) ||
                !isValidPhoneNumber(values.PhoneNumber)
              } */
              class="mt-1 block md:w-full select-none font-nunito rounded-l bg-[#882699] hover:bg-[#cf3be9] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button"
            >
              Continue
            </button>
          </form>
        </div>
        <Dialog open={size === "xl"} size={size || "md"} handler={handleOpen}>
          <DialogHeader>
            ADULIS MONEY SERVICES Terms and Conditions
          </DialogHeader>
          <DialogBody style={{ overflow: "auto", maxHeight: "680px" }}>
            <h1>We are working on it...</h1>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => handleOpen(null)}
              className="mr-1  md:text-[15px] text-[12px]"
            >
              <span>Cancel</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};
export default RegisterStepOne;
