import React, { useState } from "react";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import UserService from "../../services/user.service";
import { emailTypes } from "../../utils/constants";
const RegisterStepTwo = ({ onVerifyComplete, email, originalSentCode }) => {
  const [enteredCode, setEnteredCode] = useState("");
  const [sentCode, setSentCode] = useState(originalSentCode || "");
  const [error, setError] = useState("");
  const [alert, setAlert] = React.useState("");
  const handleVerify = () => {
    if (!!enteredCode && enteredCode.length) {
      if (sentCode == enteredCode) {
        onVerifyComplete();
      } else setAlert("fail");
    } else {
      setAlert("fail");
    }
  };
  const resend = () => {
    UserService.sendVerificationCode({
      email: email,
      emailType: emailTypes.VERIFY,
    })
      .then((res) => {
        //refresh modal
        setEnteredCode("");
        setAlert("success");
        setSentCode(res.code);
      })
      .catch((err) => {
        setAlert("Alert");
      });
  };
  return (
    <div class="w-full max-w-[34rem] mt-20 m-auto">
      {alert && (
        <AlertCustomStyles
          status={alert}
          message={
            alert == "success"
              ? "New code sent. check your email"
              : "Invalid or incorrect Code, try again!"
          }
        />
      )}

      <div class="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full  md:mb-2rounded-2xl">
        <div class="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div class="flex flex-col items-center justify-center text-center space-y-2">
            <div class="font-semibold text-3xl">
              <p>Email Verification</p>
            </div>
            <div class="flex flex-row text-sm font-medium text-gray-600">
              <p>
                We have sent a code to your email{"   "}
                <b>
                  <span className="text-black">{email}</span>
                </b>
              </p>
            </div>
          </div>
          <div>
            <div class="flex flex-col space-y-16">
              <div class="  mx-auto w-full max-w-xs">
                <div class="w-[300px] h-16 ">
                  <input
                    onChange={(e) => setEnteredCode(e.target.value)}
                    value={enteredCode}
                    className="w-full h-full flex flex-col items-center justify-center text-[40px] text-center px-5 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type="text"
                    name=""
                    maxLength={4}
                    onError={error}
                  />
                </div>
                {/* <div class="w-16 h-16 ">
                    <input
                      class="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div class="w-16 h-16 ">
                    <input
                      class="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div class="w-16 h-16 ">
                    <input
                      class="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      name=""
                      id=""
                    />
                  </div> */}
              </div>

              <div class="flex flex-col space-y-5">
                <div>
                  <button
                    type="button"
                    onClick={handleVerify}
                    class="mt-6 block w-full select-none rounded-lg bg-[#882699] hover:bg-[#cf3be9] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  >
                    Verify Email
                  </button>
                </div>

                <div class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                  <p>Didn't receive code?</p>{" "}
                  <button
                    class="flex flex-row items-center text-blue-600"
                    onClick={resend}
                  >
                    Resend
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterStepTwo;
