import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Navigate,
} from "react-router-dom";

import { Auth, Guest } from "./components/middlewares";
import Dashboard from "./components/pages/Dashboard";
import Send from "./components/pages/Send";
import ForgotPassword from "./components/pages/ForgotPassword";
import Home from "./components/pages/Home";
import SuccessPage from "./components/pages/SuccessPage";
import FailurePage from "./components/pages/FailurePage";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import ContactUs from "./components/pages/ContactUs";
import "./i18n"; // Import i18n configuration

class App extends Component {
  render() {
    return (
      <div class="h-dvh overscroll-contain">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/login"
              element={
                <Guest>
                  <Login />
                </Guest>
              }
            />
            <Route
              path="/register"
              element={
                <Guest>
                  <Register />
                </Guest>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <Guest>
                  <ForgotPassword />
                </Guest>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Auth>
                  <Dashboard />
                </Auth>
              }
            />
            <Route
              path="/success/*"
              element={
                <Auth>
                  <SuccessPage />
                </Auth>
              }
            />
            <Route
              path="/fail/*"
              element={
                <Auth>
                  <FailurePage />
                </Auth>
              }
            />
            <Route
              path="/Send"
              element={
                <Auth>
                  <Send />
                </Auth>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Guest>
                  <PrivacyPolicy />
                </Guest>
              }
            />
            <Route
              path="/contact-us"
              element={
                <Guest>
                  <ContactUs />
                </Guest>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <Guest>
                  <TermsAndConditions />
                </Guest>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
