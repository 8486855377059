import { Navigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import config from "../config";

const driver = config.AUTH.DRIVER;
export const Auth = ({ children }) => {
  return driver.getItem("token") && isLoggedIn()
    ? children
    : AuthService.logOut();
};

const isLoggedIn = async () => {
  await AuthService.getCurrentUser()
    .then((x) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};

export const Guest = ({ children }) => {
  return !driver.getItem("token") ? children : <Navigate to="/dashboard" />;
};
