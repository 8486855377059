export const selectedCountries = [
  "Uganda",
  "Eritrea",
  "Ethiopia",
  "Kenya",
  "Sudan",
  "Rwanda",
];
export const usdCountries = ["Uganda", "Kenya", "Rwanda", "Sudan"];
/* export const selectedCountries = [
  "Cameroon",
  "Rwanda",
  "South Africa",
  "Ethiopia",
  "Eritrea",
  "Zambia",
]; */
export const canadianProvinces = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Nova Scotia",
  "Northwest Territories",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Québec",
  "Saskatchewan",
  "Yukon",
];
export const roles = {
  ADMIN: "Admin",
  AGENT: "Agent",
  CUSTOMER: "Customer",
  TESTING: "Testing",
};
export const conversionDetails = [
  {
    country: "Eritrea",
    currency: "Nakfa",
    symbol: "Nkf",
    rate: "15",
    fee: "5",
  },
  {
    country: "Ethiopia",
    currency: "Birr",
    symbol: "ETB",
    rate: "110",
    fee: "5",
  },
  { country: "Sudan", currency: "SDG", symbol: "SDG", rate: "150", fee: "5" },
  {
    country: "Uganda",
    currency: "Shilling",
    symbol: "USh",
    rate: "200",
    fee: "5",
  },
];
export const getMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const getCustomerConstants = {
  COUNTRY: "Canada",
  LOCAL_CURRENCY: "CAD",
  USER_ID: "ba9e7ecbd28578ceea5f3b92701152c5",
  ROLE: "Customer",
};
export const emailTypes = {
  VERIFY: "verify",
  WELCOME: "welcome",
  CREATED: "transferCreated",
  COMPLETED: "transferCompleted",
  FORGOT_PASSWORD: "forgotPassword",
  CONTACT_US: "contactUs",
};
export const transferStatuses = {
  INITIATED: "Initiated",
  PENDING: "Pending",
  PAID: "Paid",
  CANCELLED: "Cancelled",
  FAILED: "Failed",
};
export const paymentStatuses = {
  NOT_COMPLETED: "Not Completed",
  COMPLETED: "Completed",
  FAILED: "Failed",
};
