import React, { useState } from "react";
import { useCountries } from "use-react-countries";
import {
  Card,
  CardBody,
  Input,
  Button,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import {
  BanknotesIcon,
  CreditCardIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import {
  isValidEmail,
  isValidName,
  isValidCardNumber,
  isValidCvc,
  isValidExpires,
} from "../../utils/helpers";
import BackButton from "../layout/BackButton";
function formatCardNumber(value) {
  const val = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = val.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || "";
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(" ");
  } else {
    return value;
  }
}
function formatExpires(value) {
  return value
    .replace(/[^0-9]/g, "")
    .replace(/^([2-9])$/g, "0$1")
    .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
    .replace(/^0{1,}/g, "0")
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
}

const CheckoutForm = ({ handlePrev, handleSubmit, cardData }) => {
  const [type, setType] = React.useState("interac");
  const [alert, setAlert] = useState("");
  const [values, setValues] = useState({
    cardNumber: cardData.cardNumber || "",
    cardExpires: cardData.cardExpires || "",
    email: cardData.email || "",
    cvc: cardData.cvc || "",
    holderName: cardData.holderName || "",
  });
  let [errors, setErrors] = useState({
    email: 0,
    cardNumber: 0,
    cardExpires: 0,
    cvc: 0,
    holderName: 0,
  });
  const handleCardSubmit = () => {
    if (
      Object.values(errors).every((v) => v === 0) &&
      !Object.values(values).some((x) => x == "")
    ) {
      handleSubmit({ paymentType: "MasterCard", data: values });
      setAlert("");
    } else {
      setAlert("fail");
    }
  };
  const handleInteracSubmit = () => {
    handleSubmit({ paymentType: "CPI" });
    setAlert("");
  };
  const handleValidation = (e) => {
    if (e.target.name == "email")
      !isValidEmail(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "holderName")
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "cardExpires") {
      !isValidExpires(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    }
    if (e.target.name == "cvc")
      !isValidCvc(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "cardNumber")
      !isValidCardNumber(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
  };
  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Card className="w-full max-w-[34rem] mt-40 m-auto">
      <BackButton handlePrev={handlePrev} />
      <CardBody className="md:p-6 px-3">
        {alert && (
          <div className="mb-2">
            <AlertCustomStyles status={alert} message={"Complete all fields"} />
          </div>
        )}
        <Tabs value={type}>
          <TabsHeader className="relative z-0 ">
            <Tab
              value="interac"
              className={type == "interac" ? "font-semibold" : ""}
              onClick={() => setType("interac")}
            >
              Pay with Interac®
            </Tab>
            <Tab
              value="card"
              disabled
              className={type == "card" ? "font-semibold" : ""}
              onClick={() => setType("card")}
            >
              {/* Pay with Card */}
            </Tab>
          </TabsHeader>
          <TabsBody
            className=""
            animate={{
              initial: {
                x: type === "card" ? 400 : -400,
              },
              mount: {
                x: 0,
              },
              unmount: {
                x: type === "card" ? 400 : -400,
              },
            }}
          >
            <TabPanel value="card" className="p-0" disabled>
              <form className="md:mt-12 mt-8 flex flex-col gap-1 md:gap-4">
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-2 font-medium"
                  >
                    Your Email
                  </Typography>
                  <Input
                    type="email"
                    placeholder="name@mail.com"
                    value={values["email"]}
                    onChange={onChange}
                    name="email"
                    onBlur={handleValidation}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    error={errors["email"]}
                  />
                </div>

                <div className="md:my-6 my-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-2 font-medium "
                  >
                    Card Details
                  </Typography>

                  <Input
                    maxLength={19}
                    value={formatCardNumber(values["cardNumber"])}
                    name="cardNumber"
                    onBlur={handleValidation}
                    onChange={onChange}
                    error={errors["cardNumber"]}
                    icon={
                      <CreditCardIcon className="absolute left-0 h-4 w-4 text-blue-gray-300" />
                    }
                    placeholder="0000 0000 0000 0000"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  <div className="my-4 flex items-center gap-4">
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-2 font-medium"
                      >
                        Expires
                      </Typography>
                      <Input
                        maxLength={5}
                        value={formatExpires(values["cardExpires"])}
                        name="cardExpires"
                        onBlur={handleValidation}
                        onChange={onChange}
                        containerProps={{ className: "min-w-[72px]" }}
                        error={errors["cardExpires"]}
                        placeholder="00/00"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-2 font-medium"
                      >
                        CVC
                      </Typography>
                      <Input
                        maxLength={4}
                        containerProps={{ className: "min-w-[72px]" }}
                        placeholder="000"
                        name="cvc"
                        onBlur={handleValidation}
                        value={values["cvc"]}
                        error={errors["cvc"]}
                        onChange={onChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-2 font-medium"
                  >
                    Holder Name
                  </Typography>
                  <Input
                    placeholder=""
                    value={values["holderName"]}
                    onChange={onChange}
                    error={errors["holderName"]}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    name="holderName"
                    onBlur={handleValidation}
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-2 flex items-center justify-center gap-2 font-medium text-[13px] opacity-60"
                >
                  <LockClosedIcon className="-mt-0.5 h-4 w-4" /> Payments are
                  secure and encrypted
                </Typography>
                <div className="m-auto flex justify-between p-3">
                  <Button
                    className="bg-[#ab47bc] mt-3 mb-3 normal-case md:text-[15px] text-[12px]"
                    onClick={() => handleCardSubmit()}
                    size="sm"
                  >
                    Card Pay
                  </Button>
                </div>
              </form>
            </TabPanel>
            <TabPanel value="interac" className="p-0">
              <form className="mt-16 flex flex-col gap-4 ">
                <div className="flex flex-col gap-8 m-auto  ">
                  {/* <Radio
                    name="description"
                    label={
                      <img
                        className="h-28  w-full rounded-lg object-cover object-center"
                        src="/etransfer.png"
                        alt="etransfer"
                      />
                    }
                    containerProps={{
                      className: "-mt-5",
                    }}
                    onClick={() => setInteracPaymentMethod("Interac Etransfer")}
                  />
                  <Radio
                    name="description"
                    label={
                      <img
                        className="h-28 w-full rounded-lg object-cover object-center"
                        src="/interacOnline.png"
                        alt="online"
                      />
                    }
                    onClick={() => setInteracPaymentMethod("Interac Online")}
                    containerProps={{
                      className: "-mt-5",
                    }}
                  /> */}
                  <div className="flex flex-col gap-4">
                    {/* <Radio
                      name="terms"
                      defaultChecked
                      label={
                        <Typography
                          color="blue-gray"
                          className="flex font-medium text-blue-gray-500"
                        >
                          Pay with
                          <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            className="hover:text-blueg-gray-900 font-semibold transition-colors"
                          >
                            &nbsp;E-Transfer
                          </Typography>
                          .
                        </Typography>
                      }
                      onClick={() =>
                        setInteracPaymentMethod("Interac Etransfer")
                      }
                    />
                    <Radio
                      name="terms"
                      label={
                        <Typography
                          color="blue-gray"
                          className="flex font-medium text-blue-gray-500"
                        >
                          Pay with
                          <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            className="hover:text-blueg-gray-900 font-semibold transition-colors"
                          >
                            &nbsp;Interac-Online
                          </Typography>
                          .
                        </Typography>
                      }
                      onClick={() => setInteracPaymentMethod("Interac Online")}
                    /> */}
                    <img
                      src="/interac.png"
                      className="w-32 h-32"
                      alt="interac"
                      onClick={() => handleInteracSubmit()}
                    ></img>
                  </div>
                </div>
                {/* <Typography
                  variant="small"
                  color="gray"
                  className="flex items-center justify-center gap-2 font-medium text-[13px] opacity-60"
                >
                  <LockClosedIcon className="-mt-0.5 h-4 w-4" /> Payments are
                  secure and encrypted
                </Typography> */}
                <div className="m-auto flex flex-col justify-center p-3 items-center">
                  {/* {interacPaymentMethod && (
                    <Button
                      className="bg-[#ab47bc] mt-3 mb-3 normal-case md:text-[15px] text-[12px]"
                      onClick={() => handleInteracSubmit()}
                      size="sm"
                    >
                      Pay with {interacPaymentMethod}
                    </Button>
                  )} */}
                  <Button
                    className="bg-[#ab47bc] mt-3 mb-3 w-40 normal-case md:text-[15px] text-[12px]"
                    onClick={() => handleInteracSubmit()}
                    size="sm"
                  >
                    Pay with Interac®
                  </Button>
                  <Typography
                    variant="small"
                    color="gray"
                    className="flex items-center justify-center gap-2 font-medium text-[13px] opacity-60"
                  >
                    {/* <LockClosedIcon className="-mt-0.5 h-4 w-4" /> */} ®
                    Trade-mark of Interac Corp. Used under license
                  </Typography>
                </div>
              </form>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </CardBody>
    </Card>
  );
};
export default CheckoutForm;
