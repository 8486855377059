import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Card, Button, Alert, Typography } from "@material-tailwind/react";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import TransferService from "../../services/transfer.service";
const FailurePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const transactionId = params.get("identifier");
    TransferService.cancelTransfer({ ticketNo: transactionId }).then(() => {
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    });
  }, [location.search]);

  return (
    <div className=" w-full h-full object-center mt-[18%]">
      <div className="flex flex-col items-center">
        <div className=" text-red-500 font-semibold text-xl mb-10">
          Payment Cancelled
        </div>
        <SpinnerWithInfo message="Please wait..." />
      </div>
    </div>
  );
};

export default FailurePage;
