import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemPrefix,
  Avatar,
  Card,
  Dialog,
  Typography,
  Button,
} from "@material-tailwind/react";
import AddressInfoEditorForm from "../editor-forms/AddressInfoEditorForm";
import { StickyNavbar } from "../layout/StickyNavBar";
import EmailInfoEditorForm from "../editor-forms/EmailInfoEditorForm";
import PhoneInfoEditorForm from "../editor-forms/PhoneInfoEditorForm";
import PasswordInfoEditorForm from "../editor-forms/PasswordInfoEditorForm";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import AuthService from "../../services/auth.service";
import { formatPhone } from "../../utils/helpers";
const profileDownItems = [
  { id: "phone", label: "Phone" },
  { id: "address", label: "Address" },
  { id: "password", label: "Password" },
];

const Account = () => {
  const [editor, setEditor] = useState("");
  const [alert, setAlert] = useState("");
  const [openD, setOpenD] = useState(false);
  const responseFromDialog = (e) => {};
  const [loggedInUser, setLoggedInUser] = useState([]);
  const [type, setType] = useState("");
  const onUpdate = (res) => {
    if (res.result) {
      setAlert(res.result);
      setType(res.type);
    }
    setOpenD(false);
    setEditor("");
  };
  const openDialog = (dialogType) => {
    setOpenD(true);
    setEditor(dialogType);
  };
  useEffect(() => {
    (async () => {
      let loggedInUser = await AuthService.getCurrentUser();
      setLoggedInUser(loggedInUser);
    })();
  }, [alert]);
  return (
    <div class="mx-auto md:max-w-screen-md  max-w-[400px] max-h md:py-[8%] font-nunito mt-[2%] md:mt-0">
      {loggedInUser.length == 0 && (
        <div className="mt-[20%]">
          <SpinnerWithInfo message="Please wait..." />
        </div>
      )}
      {loggedInUser.length != 0 && (
        <>
          <div className="sticky top-0 z-50 flex md:hidden">
            <StickyNavbar pageType={"mobileView"} />
          </div>
          <div class="relative mt-16 md:mt-0 flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto">
            {alert && alert == "fail" && (
              <AlertCustomStyles
                status={alert}
                message={`Updating ${type} failed, try again!`}
              />
            )}
            {alert && alert == "success" && (
              <AlertCustomStyles
                status={alert}
                message={`${type} updated successfully!`}
              />
            )}
            <Card className="w-full md:p-12 rounded-none shadow-none md:rounded-lg md:shadow-sm">
              <List className="font-nunito">
                <ListItem className="flex   justify-between">
                  <Typography
                    variant="h6"
                    className="font-nunito basis-1/4 "
                    color="blue-gray"
                  >
                    Name
                  </Typography>
                  <div className="basis-2/4">
                    {loggedInUser["firstName"] + " " + loggedInUser["lastName"]}
                  </div>
                </ListItem>
                <hr></hr>
                <ListItem className="flex  justify-between items-center">
                  <Typography
                    variant="h6"
                    className="font-nunito basis-1/4 "
                    color="blue-gray"
                  >
                    DOB
                  </Typography>
                  <div className="basis-2/4">{loggedInUser["dateOfBirth"]}</div>
                </ListItem>
                <hr></hr>
                <ListItem className="flex  justify-between items-center">
                  <Typography
                    variant="h6"
                    className="font-nunito basis-1/4 "
                    color="blue-gray"
                  >
                    Email
                  </Typography>
                  <div className="basis-2/4">{loggedInUser["email"]}</div>
                </ListItem>
                <hr></hr>
              </List>
              <List className="font-nunito">
                {profileDownItems.map((x) => (
                  <>
                    <ListItem className="flex  justify-between items-center">
                      <div className="flex flex-col gap-1">
                        <Typography
                          variant="h6"
                          className="font-nunito basis-1/4"
                          color="blue-gray"
                        >
                          {x.label}
                        </Typography>
                        {x.id == "phone" && (
                          <div className="flex flex-col md:flex-row basis-1/2 text-[15px]">
                            {formatPhone(loggedInUser.phone, "")}
                          </div>
                        )}
                        {x.id == "address" && (
                          <div className="flex flex-col  basis-1/2 text-[15px]">
                            <div>
                              {loggedInUser.apptNo}
                              {loggedInUser.streetAddress}
                            </div>
                            <div>
                              {loggedInUser.city}, {loggedInUser.province}
                              <div>{loggedInUser.postalCode}</div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="basis-2/4">
                        <Button
                          onClick={() => openDialog(x.id)}
                          className=" bg-[#882699] hover:bg-[#cf3be9] md:text-[15px] normal-case text-[12px]"
                          size="sm"
                        >
                          Edit
                        </Button>
                      </div>
                    </ListItem>
                    <hr></hr>
                  </>
                ))}

                <Dialog
                  size="xs"
                  open={openD}
                  handler={responseFromDialog}
                  className="bg-transparent shadow-none"
                  dismiss={{
                    escapeKey: false,
                    referencePress: false,
                    outsidePress: false,
                    ancestorScroll: false,
                    bubbles: false,
                  }}
                >
                  {editor && editor == "address" && (
                    <AddressInfoEditorForm
                      loggedInUser={loggedInUser}
                      onComplete={(res) => onUpdate(res)}
                      type="update"
                    />
                  )}
                  {editor && editor == "email" && (
                    <EmailInfoEditorForm
                      loggedInUser={loggedInUser}
                      onComplete={(res) => onUpdate(res)}
                    />
                  )}
                  {editor && editor == "phone" && (
                    <PhoneInfoEditorForm
                      loggedInUser={loggedInUser}
                      onComplete={(res) => onUpdate(res)}
                    />
                  )}
                  {editor && editor == "password" && (
                    <PasswordInfoEditorForm
                      loggedInUser={loggedInUser}
                      onComplete={(res) => onUpdate(res)}
                    />
                  )}
                </Dialog>
              </List>
            </Card>
          </div>
        </>
      )}
    </div>
  );
};
export default Account;
