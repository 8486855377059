import { Spinner } from "@material-tailwind/react";

export function SpinnerWithInfo({ message }) {
  return (
    <div class="flex flex-col gap-6 items-center ">
      <h1 class="text-xl ml-[11%] md:ml-0 font-semibold">{message}</h1>
      <Spinner color="purple" className="h-24 w-24 text-gray-900/50" />
    </div>
  );
}
