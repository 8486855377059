import React, { useState } from "react";
import { Spinner } from "@material-tailwind/react";
import { StickyNavbar } from "../layout/StickyNavBar";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import RegisterStepOne from "./RegisterStepOne";
import RegisterStepTwo from "./RegisterStepTwo";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";
import { getCustomerConstants, emailTypes } from "../../utils/constants";
import { Dialog, Card } from "@material-tailwind/react";
const Register = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [alert, setAlert] = useState("");
  const [firstStepData, setIsFirstStepData] = useState({});
  const [redirecting, setRedirecting] = useState(false);
  const [sentCode, setSentCode] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const handleNext = async (data) => {
    setMsg("Please wait...");
    setLoading(true);
    setIsFirstStepData(data);
    setAlert("");
    const verify = await UserService.sendVerificationCode({
      email: data.email,
      emailType: emailTypes.VERIFY,
    });
    if (verify.code) {
      setSentCode(verify.code);
      setActiveStep((cur) => cur + 1);
      setLoading(false);
    } else {
      setLoading(false);
      setAlert("fail");
    }
  };
  const handlePrev = () => {
    setActiveStep((cur) => cur - 1);
    setAlert("");
  };
  const register = () => {
    const userObj = {
      firstName: firstStepData.firstName,
      lastName: firstStepData.lastName,
      phone: firstStepData.phoneNumber,
      email: firstStepData.email,
      password: firstStepData.password,
      country: getCustomerConstants.COUNTRY,
      dateOfBirth:
        firstStepData.month +
        " " +
        firstStepData.day +
        ", " +
        firstStepData.year,
      localCurrency: getCustomerConstants.LOCAL_CURRENCY,
      role: getCustomerConstants.ROLE,
      status: 1,
    };
    UserService.addUser(userObj)
      .then(async (res) => {
        setMsg("Registered! Redirecting to login...");
        setTimeout(() => {
          setLoading(false);
          navigate("/login");
        }, 5000);
      })
      .catch((err) => {
        setAlert("fail");
        setIsFirstStepData({});
        setLoading(false);
      });
  };
  const onVerifyComplete = () => {
    setLoading(true);
    register();
  };
  return (
    <div>
      {!redirecting && (
        <div className="sticky top-0 z-50">
          <StickyNavbar pageType={"register"} />
        </div>
      )}

      <div class=" mt-1 -max-w-screen-md min-h-[calc(100dvh)] md:py-12 py-3">
        {alert && (
          <AlertCustomStyles
            status={alert}
            message={
              alert == "success"
                ? "Registration was successful"
                : "Something went wrong, Please try again"
            }
          />
        )}
        <div class="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto">
          <div className="w-full md:py-4 md:px-8 px-2 py-1">
            {activeStep == 0 && !redirecting && (
              <div class="mt-1">
                <RegisterStepOne
                  onStepOneComplete={(data) => handleNext(data)}
                  firstStepData={firstStepData}
                />
              </div>
            )}
            {activeStep == 1 && !redirecting && (
              <div class="mt-1">
                <RegisterStepTwo
                  onVerifyComplete={onVerifyComplete}
                  email={firstStepData.email}
                  originalSentCode={sentCode}
                  /*  handlePrev={handlePrev}
                  showPrev={true} */
                />
              </div>
            )}
          </div>
        </div>
        {/* {redirecting && (
          <div className="">
            <SpinnerWithInfo message="Registration was successful, redirecting you to login..." />
          </div>
        )} */}
        {loading && (
          <Dialog
            size="sm"
            open={true}
            className="bg-transparent shadow-none opacity-60"
            dismiss={{
              escapeKey: false,
              referencePress: false,
              outsidePress: false,
              ancestorScroll: false,
              bubbles: false,
            }}
          >
            <Card className="mx-auto w-full max-w-[26rem] p-4">
              <SpinnerWithInfo message={msg} />
            </Card>
          </Dialog>
        )}
      </div>
    </div>
  );
};
export default Register;
