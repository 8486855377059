//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
import axiosClient from "./axios-interceptors";
class ConversionRateService {
  async editConversionRate(ConversionRateData) {
    try {
      const response = await axiosClient.post(
        "/editConversionRate",
        ConversionRateData
      );
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error adding transfer:", error);
      throw new Error("Failed to add transfer. Please try again later.");
    }
  }

  async getAllConversionRates() {
    try {
      const response = await axiosClient.get("/getAllConversionRates");
      return response.data;
    } catch (error) {
      // Log and handle specific error
      console.error("Error fetching all conversion rates:", error);
      throw new Error(
        "Failed to fetch conversion rates. Please try again later."
      );
    }
  }
}

export default new ConversionRateService();
