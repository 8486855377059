import React from "react";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import { StickyNavbar } from "../layout/StickyNavBar";
const HelloPage = ({ startSending, loggedInUser }) => {
  return (
    <div>
      <div className="sticky top-0 z-50 flex md:hidden">
        <StickyNavbar pageType={"mobileView"} />
      </div>
      <div class="mx-auto max-w-screen-md max-h py-12">
        <div class="relative text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto">
          {loggedInUser.firstName && (
            <div className="w-full py-4 px-8 flex flex-col gap-4 mt-48  justify-center">
              <div class=" m-auto text-[14px] gap-2 text-center flex flex-col px-10 py-2 bg-blue-gray-50 rounded-lg">
                <div className="animate-waving-hand md:text-[40px]">👋</div>
                <b>
                  Hi, {`${loggedInUser.firstName} ${loggedInUser.lastName}`}.
                </b>
              </div>
              <button
                type="button"
                size="lg"
                onClick={() => startSending()}
                class="mt-1 block m-auto md:max-w-[40%]  select-none rounded-lg font-nunito bg-[#ab47bc] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Start Sending Now
              </button>
            </div>
          )}
          {!loggedInUser.firstName && (
            <div className="mt-[20%]">
              <SpinnerWithInfo message="Please wait..." />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default HelloPage;
