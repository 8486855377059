import Container from "../../utils/container";
import Banner from "../layout/Banner";
import { StickyNavbar } from "../layout/StickyNavBar";
import Footer from "./Footer";
const TermsAndConditions = () => {
  return (
    <div>
      <div className="sticky top-0 z-50">
        <StickyNavbar pageType="static" />
      </div>

      <div class="bg-gray-100 mt-6">
        <div class="container mx-auto px-4 py-8">
          <h1 class="text-3xl font-bold mb-4">Terms and Conditions</h1>

          <p class="mb-4">
            This Agreement, effective as of 01/04/2024, outlines the terms
            governing your use of the services provided through
            https://www.adulismoneyservices.com/ (“Service”). Your utilization
            of the Service implies your acceptance of these terms. If you do not
            agree to abide by these terms, refrain from using the Service. By
            accessing and using the Service, you consent to the terms of this
            Agreement. In this Agreement, the terms “Adulis Money Services”,
            “we”, “us”, and “our” refer to Adulis Money Services, Inc.,
            including its employees, directors, successors, and assignees. The
            terms “you” and “your” pertain to users of the Service, whether
            acting as Senders or Recipients.
          </p>

          <h2 class="text-2xl font-bold mb-2">1. Service:</h2>

          <p class="mb-4">
            We recommend using the Service for sending money to friends and
            family, discouraging its use for transactions involving unfamiliar
            parties. The Service enables users to conduct international money
            transfers from Canada to destinations serviced by Adulis Money
            Services. A 'Sender' employs the Service for sending money, with a
            'Transaction' constituting the act of transferring funds through the
            platform. The 'Transaction Amount' denotes the Canadian dollar sum
            provided by the sender to Adulis Money Services for onward
            transmission to the Recipient. Meanwhile, the 'Payout Amount'
            represents the sum disbursed to the Recipient.
          </p>

          <h2 class="text-2xl font-bold mb-2">
            2. Why we collect personal information:
          </h2>

          <ul class="list-disc list-inside mb-4">
            <li>
              <b>Customer Identification Program.</b> Law requires we obtain,
              verify, and record information about you. We may require that you
              provide us with nonpublic, personal, identifying information. We
              may also lawfully obtain information about you from other sources
              without your knowledge, including non-personal identifying
              information that we may obtain while you visit this website.
            </li>
            <li>
              <b>Government Disclosures.</b>We may provide information about you
              and your Transactions to government authorities and law
              enforcement agencies, as described in our Privacy Policy.
            </li>
            <li>
              <b>Verifying information.</b>You authorize us to make any
              inquiries, to you or to others, which are necessary to validate
              the information that you provide to us. This may include asking
              you for additional information, requiring you to take steps to
              confirm ownership of your email address or financial instruments,
              verifying your information against third party databases, or
              through other sources.
            </li>
          </ul>

          <h2 class="text-2xl font-bold mb-2">3. Payment Authorization:</h2>

          <p class="mb-4">
            By initiating and submitting a money transfer transaction through
            the Application, you grant authorization to Adulis (or its appointed
            agent) to debit your designated credit card account or bank account,
            or to charge your designated debit card for the amount of the
            transaction and Adulis's applicable service fee. You are accountable
            for ensuring that your designated payment method possesses adequate
            funds or credit for the payment of your money transfer transaction.
          </p>

          <h2 class="text-2xl font-bold mb-2">4. Service charge:</h2>

          <p class="mb-4">
            By utilizing the service, you agree to pay a service charge for each
            money transfer conducted via the Application. This service charge
            will be applied to the payment method you designate, whether it be a
            credit card, debit card, or bank account. Prior to submitting the
            money transfer, the service charge (alongside any other relevant
            fees imposed directly by us or on behalf of Canadian federal or
            provincial governmental bodies) will be presented online for your
            approval. Please note that our service charge schedule is subject to
            occasional modifications at our discretion. Additionally, apart from
            Adulis's fees, a money transfer may incur supplementary charges
            imposed by the Canadian government, the originating province, or the
            recipient's financial institution in the destination country
          </p>

          <h2 class="text-2xl font-bold mb-2">5. Service restriction:</h2>

          <p class="mb-4">
            We retain the authority, at our sole discretion, to: (i) decline a
            proposed money transfer; (ii) impose limitations on the transfer
            amount; (iii) request additional information to finalize a transfer;
            and/or (iv) undertake reasonable measures regarding a transfer to
            ensure compliance with relevant laws and regulations. By using the
            service, you agree not to attempt to bypass its parameters by using
            alternative debit or credit cards, bank accounts, providing false
            information, or employing any other methods. The service is
            exclusively intended for personal money transfer requirements of
            users. Any utilization or attempted utilization of the service for
            commercial purposes, promotion of products and services, or similar
            objectives, directly or indirectly, by you or a third party, is
            strictly prohibited.
          </p>

          <h2 class="text-2xl font-bold mb-2">
            6. Controlling Your Personal Information:
          </h2>

          <p class="mb-4">
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </p>

          <ul class="list-disc list-inside mb-4">
            <li>
              If you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time by writing to or emailing us at
              info@adulismoneyservices.com
            </li>
            <li>
              We will not sell, distribute, or lease your personal information
              to third parties unless we have your permission or are required by
              law to do so. We may use your personal information to send you
              promotional information about third parties which we think you may
              find interesting if you tell us that you wish this to happen.
            </li>
            <li>
              You may request details of personal information which we hold
              about you. If you would like a copy of the information held on
              you, please email to info@adulismoneyservices.com
            </li>
            <li>
              If you believe that any information we are holding on you is
              incorrect or incomplete, please write to or email us as soon as
              possible at the above address. We will promptly correct any
              information found to be incorrect.
            </li>
          </ul>

          <p class="mb-4">
            This Terms and conditions are subject to change without notice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
