import React, { useState } from "react";
import { StickyNavbar } from "../layout/StickyNavBar";
import { Link, useNavigate } from "react-router-dom";
import { isValidEmail, isValidPassword } from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import AuthService from "../../services/auth.service";
const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verifyMode, setVerifyMode] = useState(false);
  /* const [sentCode, setSentCode] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState(""); */
  const [values, setValues] = useState({
    Email: "",
    Password: "",
  });
  let [errors, setErrors] = useState({
    Email: 0,
    Password: 0,
  });
  const inputs = [
    {
      id: 1,
      name: "Email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Email address is invalid!",
      label: "Email",
      required: true,
    },
    {
      id: 4,
      name: "Password",
      type: "password",
      placeholder: "Password",
      errorMessage: "Password should be 8-20 characters!",
      label: "Password",
      pattern: `^[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];
  const [alert, setAlert] = useState("");
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleValidation = (e) => {
    if (e.target.name == "Email") {
      !isValidEmail(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    }
    if (e.target.name == "Password")
      !isValidPassword(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
  };
  const loginCheck = () => {
    setLoading(true);
    AuthService.login(values.Email, values.Password).then(
      (res) => {
        /* setLoggedInUserId(res.loggedInUser.id); 
        if (!res.loggedInUser.status) {
          UserService.sendVerificationCode({
            email: res.loggedInUser.email,
            emailType: emailTypes.VERIFY,
          })
            .then((res) => {
              setLoading(false);
              setSentCode(res.code);
              setVerifyMode(true);
            })
            .catch((err) => {});
        } else {
          setLoading(false);
          navigate("/dashboard");
        } */
        setLoading(false);
        navigate("/dashboard");
      },
      (error) => {
        setLoading(false);
        setAlert("fail");
        setValues({ Email: "", Password: "" });
      }
    );
  };
  return (
    <div>
      <div className="sticky top-0 z-50">
        <StickyNavbar pageType={"login"} />
      </div>

      <div class="mx-auto max-w-screen-md max-h py-12">
        {!verifyMode && (
          <div class="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto md:max-w-[70%] max-w-[90%]">
            {alert && (
              <AlertCustomStyles
                status={alert}
                message={"Signing in failed, Please try again"}
              />
            )}
            <h4 class="block font-nunito md:text-2xl text-xl antialiased font-semibold  leading-snug tracking-normal mt-12 text-blue-gray-900">
              Welcome, Please log in
            </h4>
            <form class="max-w-screen-lg mt-8 mb-2 w-80 md:w-96">
              <div class="flex flex-col gap-6 mb-1">
                {inputs.map((input) => (
                  <>
                    <h6 class="block -mb-3 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                      {input.name}
                    </h6>
                    <div class=" flex flex-col relative h-11 mb-5 w-full md:min-w-[200px]">
                      <input
                        label={input.label}
                        type={input.label == "Password" ? "password" : "email"}
                        onChange={onChange}
                        value={values[input.name]}
                        onBlur={handleValidation}
                        name={input.name}
                        disabled={loading}
                        class="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3  text-lg font-normal font-nunito text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      />
                      {errors[input.name] == 1 && (
                        <span class="text-[12px] ml-2 text-[#e53935] ">
                          {input.errorMessage}
                        </span>
                      )}
                    </div>
                  </>
                ))}
              </div>
              <p class="block mt-1  text-base antialiased font-normal  leading-relaxed text-left font-nunito text-gray-700">
                <Link to={"/forgot-password"}>
                  <span class="font-normal text-blue-500 hover:underline text-[14px]">
                    Forgot password?
                  </span>
                </Link>
              </p>
              <button
                class="mt-6  w-full select-none rounded-lg bg-[#882699] hover:bg-[#cf3be9] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md font-nunito shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                disabled={
                  !isValidEmail(values.Email) ||
                  !isValidPassword(values.Password)
                }
                onClick={loginCheck}
              >
                {loading && (
                  <>
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-5 h-5 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading ...
                  </>
                )}
                {!loading && <>Log In</>}
              </button>
              <p class="block mt-4  text-base antialiased font-normal leading-relaxed text-center font-nunito text-gray-700">
                You don't have an account?
                <Link to={"/register"}>
                  <a href="" class="font-semibold text-blue-500 ml-2 underline">
                    Register
                  </a>
                </Link>
              </p>
            </form>
          </div>
        )}
        {/* {verifyMode && (
          <RegisterStepTwo
            onVerifyComplete={login}
            email={values.Email}
            originalSentCode={sentCode}
          />
        )} */}
      </div>
    </div>
  );
};
export default Login;
