import React, { useEffect } from "react";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import {
  getPaymentToken,
  redirectToPaymentPage,
} from "../../utils/paymentHelper";
const PaymentComponent = ({
  transferData,
  loggedInUser,
  onPaymentError,
  ipAddress,
}) => {
  useEffect(() => {
    getPaymentToken(
      new URLSearchParams({
        userId: loggedInUser.id,
        transactionId: transferData.ticketNo,
        name: loggedInUser.firstName + " " + loggedInUser.lastName,
        email: loggedInUser.email,
        site: process.env.REACT_APP_SITE,
        userIp: ipAddress || "70.67.168.255",
        mobile: loggedInUser.phone,
        currency: "CAD",
        language: "en",
        amount: transferData.totalPay,
        type: "CPI",
        sandbox: process.env.REACT_APP_SANDBOX,
      })
    )
      .then((response) => {
        if (!response.errorMsg) {
          redirectToPaymentPage(
            new URLSearchParams({
              token: response.data,
            }),
            transferData.ticketNo
          )
            .then((response) => {
              if (response.redirected) {
                const redirectUrl = response.url;
                window.location.href = redirectUrl;
              } else {
                onPaymentError();
              }
            })
            .catch((error) => {
              console.error("Error:", error.message);
              onPaymentError();
            });
        } else onPaymentError();
      })
      .catch((error) => {
        console.error("Error:", error.message);
        onPaymentError();
      });
  }, []);

  return <SpinnerWithInfo message="Redirecting to payment gateway..." />;
};

export default PaymentComponent;
