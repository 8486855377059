//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
import axiosClient from "./axios-interceptors";
class TransferService {
  async getTransfers() {
    try {
      const response = await axiosClient.get("/getTransfers");
      return response.data;
    } catch (error) {
      // Log and handle specific error
      console.error("Error getting transfers:", error);
      throw new Error("Failed to fetch transfers. Please try again later.");
    }
  }
  async addTransfer(TransferData) {
    try {
      const response = await axiosClient.post("/addTransfer", TransferData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error adding transfer:", error);
      throw new Error("Failed to add transfer. Please try again later.");
    }
  }
  async editTransfer(TransferData) {
    try {
      const response = await axiosClient.post("/editTransfer", TransferData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error editing transfer:", error);
      throw new Error("Failed to edit transfer. Please try again later.");
    }
  }
  async completeTransfer(TransferData) {
    try {
      const response = await axiosClient.post(
        "/completeTransfer",
        TransferData
      );
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error editing transfer:", error);
      throw new Error("Failed to complete transfer. Please try again later.");
    }
  }
  async deleteTransfer(data) {
    try {
      const response = await axiosClient.post("/deleteTransfer", data);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error deleting transfer:", error);
      throw new Error("Failed to delete transfer. Please try again later.");
    }
  }

  async getAllTransfers() {
    try {
      const response = await axiosClient.get("/getAllTransfers");
      return response.data;
    } catch (error) {
      // Log and handle specific error
      console.error("Error fetching all transfers:", error);
      throw new Error("Failed to fetch all transfers. Please try again later.");
    }
  }
  async getNotCompletedPayments(userData) {
    try {
      const response = await axiosClient.post(
        "/getNotCompletedPayments",
        userData
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching incomplete transfers:", error);
      throw new Error(
        "Failed fetching incomplete transfers. Please try again later."
      );
    }
  }
  async getPayOutTransfers() {
    try {
      const response = await axiosClient.get("/getPayOutTransfers");
      return response;
    } catch (error) {
      console.error("Error fetching payouts:", error);
      throw new Error("Failed to fetch payouts. Please try again later.");
    }
  }
  async getTransferInfo(infoData) {
    try {
      const response = await axiosClient.post("/getTransferInfo", infoData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error getting Transfers:", error);
      throw new Error("Failed to get Transfers. Please try again later.");
    }
  }
  async filterTransfers(filterData) {
    try {
      const response = await axiosClient.post("/filterTransfers", filterData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error filtering transfers:", error);
      throw new Error("Failed to filter transfers. Please try again later.");
    }
  }
  async cancelTransfer(cancelData) {
    try {
      const response = await axiosClient.post("/cancelTransfer", cancelData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error cancelling transfer:", error);
      throw new Error("Failed to cancel transfer. Please try again later.");
    }
  }
}

export default new TransferService();
