import Container from "../../utils/container";
import Banner from "../layout/Banner";
import { StickyNavbar } from "../layout/StickyNavBar";
import React, { useEffect } from "react";
import Footer from "./Footer";
const Home = () => {
  useEffect(() => {
    localStorage.removeItem("token");
  }, []);
  return (
    <div>
      <div className="sticky top-0 z-50">
        <StickyNavbar />
      </div>

      <div>
        <Banner />

        <Container className="wow fadeInUp relative -mt-[110px]">
          <div className=" flex gap-1 md:gap-4 justify-between md:px-60 px-6 bg-white border border-gray-200 border-opacity-50 shadow-2xl md:rounded-xl rounded-sm">
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/Eritrea.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="Eritrea"
              />
            </div>
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/Ethiopia.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="Ethiopia"
              />
            </div>
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/Sudan.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="Sudan"
              />
            </div>
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/Uganda.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="Uganda"
              />
            </div>
          </div>
          {/* <div className=" flex gap-1 md:gap-4 justify-between md:px-60 px-6 bg-white border border-gray-200 border-opacity-50 shadow-2xl md:rounded-xl rounded-sm">
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/Cameroon.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="Eritrea"
              />
            </div>
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/Rwanda.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="Rwanda"
              />
            </div>
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/South_Africa.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="South Africa"
              />
            </div>
            <div className=" text-center md:py-10 py-3 lg:py-16 space-y-3 text-indigo-900 font-medium">
              <img
                src="/Zambia.png"
                className="md:w-24 md:h-24 w-8 h-8"
                alt="Zambia"
              />
            </div>
          </div> */}
        </Container>

        <div className="wow fadeInUp text-center text-indigo-900 space-y-8 md:mt-28 mt-2 mb-2 md:mb-16">
          <h4 className="text-xl lg:text-2xl font-bold">
            Send money in a heartbeat
          </h4>

          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
            The World At Your <br className="hidden lg:block" /> Fingertips
          </h1>

          <p className="text-sm sm:text-base font-medium">
            Register to start saving on international money transfers and
            currency exchange.
          </p>
        </div>

        <Container className="wow fadeInUp flex items-center flex-col-reverse xl:flex-row xl:space-y-0 xl:space-x-5">
          <div className="w-full xl:w-1/2">
            <img
              src="/images/global-payment-img.png"
              className="h-auto object-contain object-center align-middle mt-16 xl:mt-0"
              alt=""
            />
          </div>

          <div className="w-full xl:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-6 text-indigo-900">
            <div className="transition-all duration-300 border border-indigo-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
              <div className="flex items-center justify-center">
                <img src="/images/global-payment-icon-1.png" alt="" />
              </div>
              <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">
                Peace of Mind
              </h3>
              <p className="max-w-lg">
                Experience tranquility in your financial transactions with our
                secure and compliant money services.
              </p>
            </div>

            <div className="transition-all duration-300 border border-indigo-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
              <div className="flex items-center justify-center">
                <img src="/images/global-payment-icon-2.png" alt="" />
              </div>
              <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">
                Reliability
              </h3>
              <p className="max-w-lg">
                You can count on us to deliver consistent and reliable service
                every time.
              </p>
            </div>

            <div className="transition-all duration-300 border border-indigo-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
              <div className="flex items-center justify-center">
                <img src="/images/global-payment-icon-3.png" alt="" />
              </div>
              <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">
                100% Transparent
              </h3>
              <p className="max-w-lg">
                Enjoy complete clarity. No hidden fees, just straightforward
                transactions for your confidence.
              </p>
            </div>

            <div className="transition-all duration-300 border border-indigo-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
              <div className="flex items-center justify-center">
                <img src="/images/global-payment-icon-4.png" alt="" />
              </div>
              <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">
                International Network
              </h3>
              <p className="max-w-lg">
                Connect globally with our extensive international network.
                Seamlessly transfer funds across borders with confidence and
                ease.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
