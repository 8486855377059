import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Card, Button, Alert, Typography } from "@material-tailwind/react";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionStatus, setTransactionStatus] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const transactionId = params.get("transactionId");
    const status = params.get("status");
    setTransactionStatus(status);
    setTimeout(() => {
      navigate("/dashboard");
    }, 1000);
  }, [location.search]);

  return (
    <div className="w-full max-w-[34rem]  ml-[35%] mt-[10%] ">
      <SpinnerWithInfo message="Please wait..." />
    </div>
  );
};

export default SuccessPage;
