import React, { useState, useEffect } from "react";
import {
  Card,
  Select,
  Option,
  Input,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { selectedCountries, usdCountries } from "../../utils/constants";
import { useCountries } from "use-react-countries";
import { isValidAmount } from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { useNavigate } from "react-router-dom";
import ConversionRateService from "../../services/conversionRate.service";
import { useTranslation } from "react-i18next";

const TransferForm = ({ type, amtData, handleFormContinue }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch countries and sort them
  const countries = useCountries()
    .countries.filter((x) => selectedCountries.includes(x.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  const [alert, setAlert] = useState("");
  const [rate, setRate] = useState("");
  const [USDRate, setUSDRate] = useState("");
  const [discountedFee, setDiscountedFee] = useState("");
  const [originalFee, setOriginalFee] = useState("");
  const [countryPerHundredFee, setCountryPerHundredFee] = useState(0);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [recipientCurrency, setRecipeintCurrency] = React.useState("");
  const [selectedCountryName, setSelectedCountryName] = React.useState("");

  const [values, setValues] = useState({
    /* rCountry: countries[0]?.name || "",
    sAmount: 100,
    rAmount: "",
    rCurrency: "NKF",
    sCurrency: "CAD",
    totalPay: 0, */
    rCountry: amtData.rCountry || countries[0].name,
    sAmount: amtData.sAmount || 100,
    rAmount: amtData.rAmount || "",
    rCurrency: amtData.rCurrency || "NKF",
    sCurrency: "CAD",
    totalPay: amtData.totalPay || 0,
  });

  const [errors, setErrors] = useState({
    sAmount: 0,
    rAmount: 0,
  });
  const handleCurrencyChange = async (currency) => {
    setRecipeintCurrency(currency);
    await fetchRealTimeRateSAmountChange(
      values.rCountry,
      values.sAmount,
      currency
    );
  };
  const calculateTierBasedFee = (amount, feeRate) => {
    const roundedAmount = Math.round(amount / 50) * 50;

    let discount = 0;

    // Define fee tiers based on amount
    if (amount > 1000) {
      discount = 0; // 10% discount for amounts over 1000
    } else if (amount > 500) {
      discount = 0; // 5% discount for amounts between 501 and 1000
    } else {
      discount = 0; // No discount for amounts up to 500
    }
    const originalFee = roundedAmount * parseFloat(feeRate);
    const discountedFee = Math.round(originalFee * (1 - discount)); // Apply discount
    setOriginalFee(originalFee);
    setDiscountedFee(discountedFee);
    return Math.round(discountedFee); // Return the rounded fee
  };

  const handleValidation = (e) => {
    if (e.target.name == "sAmount" || e.target.name == "rAmount") {
      setErrors({
        ...errors,
        [e.target.name]: !isValidAmount(e.target.value) ? 1 : 0,
      });
    }
  };
  const fetchRealTimeRateRAmountChange = async (
    countryName,
    recipientAmount,
    recipientCurrency
  ) => {
    const rates = await ConversionRateService.getAllConversionRates();
    const USDRates = rates.find((x) => x.country === "USA");
    const fee = USDRates.fee;
    const rate = USDRates.rate;
    const recipientAmountValid = parseFloat(recipientAmount) || 0;
    setSelectedCountryName(countryName);
    // Convert the send amount (CAD) to USD based on the conversion rate and round it
    const sAmount = Math.round(
      parseFloat(recipientAmountValid) * parseFloat(rate)
    );

    // Calculate the fee for the amount in USD (get the fee for 100 USD in CAD and compute) and round it
    const feePerHundredCAD = parseFloat(fee) || 0; // The fee for 100 USD in CAD
    const totalFee = calculateTierBasedFee(
      recipientAmount,
      feePerHundredCAD / 100
    ); // Fee for the converted amount in USD

    // Calculate totalPay (CAD) = sAmount (CAD) + fee (CAD) and round it
    const totalPay = Math.round(parseFloat(sAmount) + totalFee);
    setDiscountedFee(totalFee);
    setRate(rate);
    // Set the recipient amount and totalPay, rounded to the nearest whole number
    setValues((prev) => ({
      ...prev,
      sAmount: sAmount,
      totalPay: totalPay,
    }));
  };
  const fetchRealTimeRateSAmountChange = async (
    countryName,
    senderAmount,
    recipientCurrency
  ) => {
    const rates = await ConversionRateService.getAllConversionRates();
    const selectedCountry = rates.find((x) => x.country === countryName);
    setSelectedCountryName(countryName);
    if (recipientCurrency == "USD") {
      // Get USD to CAD rate from ConversionRateService
      const USDRates = rates.find((x) => x.country === "USA");
      const fee = USDRates.fee || 0;
      const rate = USDRates.rate || 0;
      const sAmount = parseFloat(senderAmount) || 0;
      // Convert the send amount (CAD) to USD based on the conversion rate and round it
      const rAmount = Math.round(parseFloat(sAmount) / parseFloat(rate)) || 0;

      // Calculate the fee for the amount in USD (get the fee for 100 USD in CAD and compute) and round it
      const feePerHundredCAD = parseFloat(fee); // The fee for 100 USD in CAD
      const totalFee = calculateTierBasedFee(rAmount, feePerHundredCAD / 100); // Fee for the converted amount in USD

      // Calculate totalPay (CAD) = sAmount (CAD) + fee (CAD) and round it
      const totalPay = Math.round(parseFloat(sAmount) + totalFee);
      setDiscountedFee(totalFee);
      setRate(rate);
      // Set the recipient amount and totalPay, rounded to the nearest whole number
      setValues((prev) => ({
        ...prev,
        rAmount: rAmount || 0,
        totalPay: totalPay || 0,
      }));
    } else {
      // Non-USD currency logic
      setRate(selectedCountry.rate);
      setCountryPerHundredFee(selectedCountry.fee);
      const sAmount = parseFloat(senderAmount) || 0;
      // Calculate and round the total fee
      const totalFee = calculateTierBasedFee(
        parseFloat(sAmount),
        selectedCountry.fee / 100
      );

      setDiscountedFee(totalFee);
      // Calculate and round the recipient amount
      const recipientAmount =
        Math.round(parseFloat(sAmount) * parseFloat(selectedCountry.rate)) || 0;

      // Set values with rounded numbers
      setValues((prev) => ({
        ...prev,
        rAmount: recipientAmount || 0,
        totalPay: totalFee + Math.round(parseFloat(sAmount)) || 0,
      }));
    }
  };

  const fetchRealTimeRateCountryChange = async (countryName) => {
    const rates = await ConversionRateService.getAllConversionRates();
    const selectedCountry = rates.find((x) => x.country === countryName);
    setRate(selectedCountry.rate);
    setCountryPerHundredFee(selectedCountry.fee);
    setSelectedCountryName(countryName);
    const totalFee = calculateTierBasedFee(100, selectedCountry.fee / 100);

    const recipientAmount = 100 * parseFloat(selectedCountry.rate) || 0;
    setRecipeintCurrency(selectedCountry.symbol);
    setDiscountedFee(totalFee);
    setValues((prev) => ({
      ...prev,
      rCountry: countryName,
      rCurrency: selectedCountry.symbol,
      rAmount: recipientAmount,
      totalPay: totalFee + 100,
    }));
  };
  const fetchRealTimeRateCountryChangeUSD = async (countryName) => {
    const rates = await ConversionRateService.getAllConversionRates();
    const USDRates = rates.find((x) => x.country === "USA");
    const fee = USDRates.fee;
    const rate = USDRates.rate;

    // Convert the send amount (CAD) to USD based on the conversion rate and round it
    const sAmount = Math.round(100 * parseFloat(rate));
    const rAmount = 100;

    // Calculate the fee for the amount in USD (get the fee for 100 USD in CAD and compute) and round it
    const feePerHundredCAD = parseFloat(fee); // The fee for 100 USD in CAD
    const totalFee = calculateTierBasedFee(100, feePerHundredCAD / 100); // Fee for the converted amount in USD

    // Calculate totalPay (CAD) = sAmount (CAD) + fee (CAD) and round it
    const totalPay = Math.round(sAmount + totalFee);
    setDiscountedFee(totalFee);
    setRate(rate);
    setRecipeintCurrency("USD");
    setSelectedCountryName(countryName);
    // Set the recipient amount and totalPay, rounded to the nearest whole number
    setValues((prev) => ({
      ...prev,
      rCountry: countryName,
      sAmount: sAmount,
      rAmount: 100,
      totalPay: totalPay,
    }));
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    const newValues = { ...values, [name]: value };
    if (name == "sAmount") {
      setValues(newValues);
      await fetchRealTimeRateSAmountChange(
        values.rCountry,
        value,
        recipientCurrency
      );
    } // Fetch rate on amount change
    if (name == "rAmount") {
      setValues(newValues);
      await fetchRealTimeRateRAmountChange(
        values.rCountry,
        value,
        recipientCurrency
      );
    } // Fetch rate on amount change
  };

  const handleCountryChange = async (countryName) => {
    if (!usdCountries.includes(countryName))
      await fetchRealTimeRateCountryChange(countryName);
    // Fetch rate on country change
    else fetchRealTimeRateCountryChangeUSD(countryName);
  };

  const handleNextStep = () => {
    if (type == "homePage") {
      if (errors.sAmount === 0 && values.sAmount) {
        navigate("/login");
      } else {
        setAlert("fail");
      }
    } else {
      if (
        Object.values(errors).every((v) => v === 0) &&
        !Object.values(values).some((x) => x == "")
      ) {
        values.rate = rate;
        values.fee = discountedFee;
        values.rCurrency = recipientCurrency;
        handleFormContinue(values);
      } else {
        setAlert("fail");
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchRealTimeRateCountryChange(values.rCountry); // Fetch rate on initial load
    })();
  }, []);
  return (
    <div
      className={`w-full max-w-[400px] md:max-w-[24rem] mt-2 m-auto  rounded-none md:rounded-xl ${
        type == "homePage" ? "md:ml-9" : ""
      }`}
    >
      <form class="max-w-screen-lg  md:mt-1 md:mb-2 w-80  ">
        {alert && (
          <AlertCustomStyles status={alert} message={"Fill required fields!"} />
        )}
        <div class="flex flex-col gap-5 mb-0">
          <h6 class="block mt-3 -mb-3 text-[18px] font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
            You are sending to
          </h6>
          <div className="w-90 md:mt-5">
            <Select
              size="lg"
              label="Send to"
              selected={(element) =>
                element &&
                React.cloneElement(element, {
                  disabled: true,
                  className:
                    "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                })
              }
              value={values["rCountry"]}
              onChange={(e) => handleCountryChange(e)}
            >
              {countries.map(({ name, flags, currencies }) => (
                <Option
                  key={name}
                  value={name}
                  className="flex items-center gap-2"
                >
                  <img
                    src={flags.svg}
                    alt={name}
                    className="h-5 w-5 rounded-full object-cover"
                  />
                  {name}
                </Option>
              ))}
            </Select>
          </div>
          <div class="flex flex-col  w-full gap-2 md:gap-0 ">
            <div class="relative md:h-22 h-22 w-full m-auto bg-indigo-50 md:p-3 py-1 px-3 rounded-lg ">
              <div class="flex flex-col gap-4 md:gap-0">
                <div className="relative flex flex-col gap-3">
                  <h6 class="block -mb-3 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                    You send
                  </h6>
                  <div className="relative flex w-full max-w-[21rem]">
                    <div className="flex flex-col">
                      <Input
                        color="indigo"
                        name="sAmount"
                        value={values["sAmount"]}
                        onBlur={handleValidation}
                        onChange={onChange}
                        error={errors["sAmount"]}
                        className={`${
                          errors["sAmount"]
                            ? "!border-t-red-600 focus:!border-t-red-600"
                            : ""
                        } rounded-r-none  !border-t-blue-gray-200 focus:!border-t-purple-600 text-gray-900`}
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        containerProps={{
                          className:
                            "md:min-h-[50px] min-h-[50px] min-w-[14rem] max-w-[14rem] ",
                        }}
                        style={{ fontSize: "25px", fontWeight: "700" }}
                      />
                    </div>
                    <Menu placement="bottom-start">
                      <MenuHandler>
                        {/* <Button
                          ripple={false}
                          variant="text"
                          color="blue-gray"
                          className="flex cursor-default md:min-h-[30px] min-h-[30px] h-10 items-center gap-2 rounded-r-none border border-r-1 border-blue-gray-200 bg-blue-gray-500/10 pl-3 disabled:text-black"
                        >
                          CAD
                        </Button> */}
                        <Button
                          variant="text"
                          className="flex p-2 items-center gap-3 text-base font-normal capitalize tracking-normal min-w-[70px] bg-gray-400 rounded-l-none"
                        >
                          CAD
                        </Button>
                      </MenuHandler>
                    </Menu>
                  </div>
                  {errors["sAmount"] == 1 && (
                    <span class="text-[10px] md:text-[10px] ml-2 font-semibold text-[#e53935] ">
                      Send amount must be between 50.00 and 2999.00
                    </span>
                  )}
                </div>
                <div className="flex md:mb-4 text-purple-600 md:mt-6 items-center bg-green-100 border rounded-lg p-3">
                  <div className="text-[18px]">Rate</div>
                  <div className="flex text-[18px] text-purple-600 m-auto font-bold">
                    <span>
                      1.00 {recipientCurrency == "USD" ? "USD" : "CAD"}{" "}
                    </span>
                    <ArrowRightIcon className="h-3 w-7 opacity-80 mt-[7px] " />
                    <span>
                      {rate}{" "}
                      {recipientCurrency == "USD" ? "CAD" : values["rCurrency"]}
                    </span>
                  </div>
                </div>
                <div className="relative flex flex-col mb-3 gap-3">
                  <h6 class="block -mb-3 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                    Recipient gets
                  </h6>
                  <div className="relative flex w-full max-w-[21rem]">
                    <Input
                      name="rAmount"
                      disabled={recipientCurrency == "USD" ? false : true}
                      value={values["rAmount"].toLocaleString()}
                      error={errors["rAmount"]}
                      className={`${
                        errors["rAmount"]
                          ? "!border-t-red-600 focus:!border-t-red-600"
                          : ""
                      } rounded-r-none  !border-t-blue-gray-200 focus:!border-t-purple-600 text-gray-900`}
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      onBlur={handleValidation}
                      onChange={onChange}
                      containerProps={{
                        className:
                          "md:min-h-[50px] min-h-[50px] min-w-[14rem] max-w-[14rem]  ",
                      }}
                      style={{ fontSize: "25px", fontWeight: "700" }}
                    />
                    <Menu
                      placement="bottom-start"
                      open={openMenu}
                      handler={setOpenMenu}
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex p-2  items-center gap-3 text-base font-normal capitalize tracking-normal  bg-gray-400 rounded-l-none"
                          onClick={() =>
                            usdCountries.includes(selectedCountryName)
                              ? ""
                              : setOpenMenu(!openMenu)
                          } // Toggle menu on click
                        >
                          {usdCountries.includes(selectedCountryName)
                            ? "USD"
                            : recipientCurrency}
                          {usdCountries.includes(selectedCountryName) ? (
                            ""
                          ) : (
                            <ChevronDownIcon
                              strokeWidth={2.5}
                              className={`h-3.5 w-3.5 transition-transform duration-200 ${
                                openMenu ? "rotate-180" : ""
                              }`}
                            />
                          )}
                        </Button>
                      </MenuHandler>
                      {!usdCountries.includes(selectedCountryName) && (
                        <MenuList>
                          <MenuItem
                            onClick={() =>
                              handleCurrencyChange(values["rCurrency"])
                            }
                          >
                            {values["rCurrency"]}
                          </MenuItem>
                          <MenuItem onClick={() => handleCurrencyChange("USD")}>
                            USD
                          </MenuItem>
                        </MenuList>
                      )}
                    </Menu>
                  </div>
                  {errors["rAmount"] == 1 && (
                    <span class="text-[10px] md:text-[10px] ml-2 font-semibold text-[#e53935] ">
                      Recipient amount is not valid!
                    </span>
                  )}
                </div>
              </div>
            </div>
            <hr className="hidden md:block mt-4" />
            <div class="relative md:px-6 px-8 md:w-[100%] ">
              <div className="flex  gap-2 md:gap-4 w-full md:mb-4 md:mt-4 mb-2 mt-2 ">
                <div className="flex flex-1 flex-col gap-1">
                  <div className=" font-bold  md:text-[16px] text-[14px]">
                    {t("fee")}:
                  </div>
                  <div className=" md:text-[15px] text-[14px]">
                    {discountedFee} CAD
                    {/* <span class="text-[10px] md:text-[10px] ml-2 font-semibold text-[#e53935] ">
                      {originalFee} CAD
                    </span> */}
                  </div>
                </div>
                <div className="flex flex-1  flex-col gap-1">
                  <div className=" font-bold text-[14px] md:text-[16px]">
                    {t("totalPay")}:
                  </div>
                  {!!values["sAmount"] && (
                    <div className="  text-[14px] font-bold md:text-[17px]">
                      {values["totalPay"] || 0} CAD
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </form>
      <div
        className={`md:mt-1 md:p-3 m-auto  md:mb-2 ${
          type == "homePage"
            ? "mb-5 mt-2"
            : "mb-10 flex justify-center items-center"
        }`}
      >
        <Button
          className=" bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
          onClick={handleNextStep}
          size="sm"
        >
          {type == "homePage" ? t("getStarted") : "Continue"}
        </Button>
      </div>
    </div>
  );
};
export default TransferForm;
