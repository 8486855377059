const username = process.env.REACT_APP_USER_NAME;
const password = process.env.REACT_APP_PASSWORD;
const campaignId = process.env.REACT_APP_CAMPAIGNID;
const paymentGateway = process.env.REACT_APP_PAYMENT_GATEWAY;
const webflowGateway = process.env.REACT_APP_WEBFLOW_GATEWAY;

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: "Basic " + btoa(username + ":" + password),
};
export async function getPaymentToken(body) {
  let paymentToken = "";
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: body,
  };
  const response = await fetch(
    `${paymentGateway + "/" + campaignId}`,
    requestOptions
  );
  if (!response.ok) {
    return { data: "", errorMsg: "Error" };
  }
  await response.text().then((result) => {
    paymentToken = JSON.parse(result).token;
  });
  return { data: paymentToken, errorMsg: "" };
}
export async function redirectToPaymentPage(body, ticketNo) {
  const redirectPaymentOptions = {
    method: "POST",
    headers: headers,
    body: body,
  };
  const response = await fetch(
    `${webflowGateway + "/webflow?transaction=" + ticketNo}`,
    redirectPaymentOptions
  );
  return response;
}
export async function completeDeposit(token, ticketNo) {
  const depositOptions = {
    method: "GET",
    headers: headers,
  };
  const response = await fetch(
    `${
      webflowGateway +
      "/webflow/deposit?token=" +
      token +
      "&transaction=" +
      ticketNo
    }`,
    depositOptions
  );
  return response;
}
export async function cancelDeposit(token, ticketNo) {
  const cancelDepositOptions = {
    method: "GET",
    headers: headers,
  };
  const response = await fetch(
    `${
      webflowGateway +
      "/webflow/cancel-deposit?token=" +
      token +
      "&transaction=" +
      ticketNo
    }`,
    cancelDepositOptions
  );
  return response;
}
