import React, { useState } from "react";
import {
  Card,
  Input,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Select,
  Option,
} from "@material-tailwind/react";
import {
  isValidName,
  isValidPhoneNumber,
  getCities,
} from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { formatPhone } from "../../utils/helpers";
import { selectedCountries } from "../../utils/constants";
import { useCountries } from "use-react-countries";
import BackButton from "../layout/BackButton";
const RecipientForm = ({
  handlePrev,
  handleNextRecipient,
  recipientData,
  selectedCountry,
}) => {
  const [alert, setAlert] = useState("");
  const { name, flags, countryCallingCode } = useCountries().countries.filter(
    (x) => x.name === selectedCountry
  )[0];
  const handleNextStep = () => {
    if (selectedCountry == "Ethiopia") {
      values.rCity = "N/A";
    }
    if (
      Object.values(errors).every((v) => v === 0) &&
      !Object.values(values).some((x) => x == "")
    ) {
      values.countryCallingCode = countryCallingCode;
      handleNextRecipient(values);
    } else {
      setAlert("fail");
    }
  };
  const cities = getCities(selectedCountry);
  const [values, setValues] = useState({
    rName: recipientData.rName || "",
    rPhoneNumber: recipientData.rPhoneNumber || "",
    rCity: recipientData.rCity || "",
  });
  let [errors, setErrors] = useState({
    rName: 0,
    rPhoneNumber: 0,
    rCity: 0,
  });
  const handleValidation = (e) => {
    if (e.target.name == "rName")
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "rCity")
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "rPhoneNumber")
      !isValidPhoneNumber(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
  };
  const handleCity = async (e) => {
    setValues({
      ...values,
      rCity: e,
    });
  };
  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.name == "rPhoneNumber"
          ? e.target.value.replace(/\D/g, "")
          : e.target.value,
    });
  };
  return (
    <Card className="w-full max-w-[400px] md:max-w-[34rem] mt-40 m-auto">
      <div className="w-full max-w-[400px] md:max-w-[34rem]  m-auto">
        <div class="mx-auto max-w-screen-md max-h py-3">
          <BackButton handlePrev={() => handlePrev("recipient")} />
          <div class="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto md:max-w-[70%] max-w-[90%]">
            <form class="max-w-screen-lg  md:mt-8 md:mb-2 w-80 sm:w-96">
              {alert && (
                <AlertCustomStyles
                  status={alert}
                  message={"Complete all all fields"}
                />
              )}
              <div class="flex flex-col gap-5 mb-5">
                <div class="flex flex-col md:gap-6 gap-4">
                  <h4 class="block font-nunito text-[18px] antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 m-auto mb-2">
                    Add details of the recipient
                  </h4>
                  <div>
                    <h6 class="block md:-mb-0  font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                      Full Name
                    </h6>
                    <div class="relative h-11 w-full md:min-w-[200px]">
                      <input
                        color="purple"
                        name="rName"
                        className="md:text-[18px] text-[18px]"
                        value={values["rName"]}
                        onBlur={handleValidation}
                        onChange={onChange}
                        label="Full Name"
                        class={`${
                          errors["rName"]
                            ? "border-red-600 !border-t-red-600"
                            : ""
                        } focus:border-purple-400  peer h-full w-full font-nunito rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3  text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border   focus:border-2  focus:border-t-transparent focus:!border-t-purple-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 `}
                      />
                    </div>
                  </div>
                  <div>
                    <h6 class="block md:-mb-0 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                      Phone Number
                    </h6>
                    <div class="relative h-11 w-full min-w-[200px]">
                      <div className="relative flex w-full max-w-[24rem]">
                        <Menu placement="bottom-start">
                          <MenuHandler>
                            <Button
                              ripple={false}
                              variant="text"
                              color="blue-gray"
                              className="flex cursor-default h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                            >
                              <img
                                src={flags.svg}
                                alt={name}
                                className="h-4 w-4 rounded-full object-cover"
                              />
                              {countryCallingCode}
                            </Button>
                          </MenuHandler>
                        </Menu>
                        <Input
                          type="tel"
                          color="purple"
                          name="rPhoneNumber"
                          value={formatPhone(
                            values["rPhoneNumber"],
                            selectedCountry
                          )}
                          onBlur={handleValidation}
                          error={errors["rPhoneNumber"]}
                          onChange={onChange}
                          maxLength={11}
                          placeholder={`${
                            selectedCountry == "Eritrea"
                              ? "(0) 000 000"
                              : "(00) 000 0000"
                          }`}
                          className={`${
                            errors["rPhoneNumber"]
                              ? "!border-t-red-600 focus:!border-t-red-600"
                              : ""
                          } rounded-l-none md:text-[16px]  text-[16px] !border-t-blue-gray-200 focus:!border-t-purple-600`}
                          /*  containerProps={{
                          className: "md:min-h-[30px] min-h-[30px]",
                        }}
                        labelProps={{
                          className: "text-[14px]",
                        }} */
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          containerProps={{
                            className: "min-w-0",
                          }}
                        />
                      </div>
                      <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                    </div>
                  </div>
                  {/*   <div class="relative h-11 w-full md:min-w-[200px] mt-2 mb-2">
                    <h6 class="block -mb-3 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                      Phone Number
                    </h6>
                    <Input
                      type="tel"
                      color="purple"
                      name="rPhoneNumber"
                      value={formatPhone(values["rPhoneNumber"])}
                      onBlur={handleValidation}
                      error={errors["rPhoneNumber"]}
                      onChange={onChange}
                      label="Phone"
                      maxLength={11}
                      placeholder="(000) 0000 0000"
                      className="md:text-[16px]  text-[16px]"
                      containerProps={{
                        className: "md:min-h-[50px] min-h-[30px]",
                      }}
                      labelProps={{
                        className: "text-[14px]",
                      }}
                    />
                  </div> */}
                  <div>
                    <h6 class="block md:-mb-0 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                      City
                    </h6>
                    {/* <div class="relative h-11 w-full md:min-w-[200px]">
                       <input
                        name="rCity"
                        value={values["rCity"]}
                        onBlur={handleValidation}
                        onChange={onChange}
                        error={errors["rCity"]}
                        className="text-[18px]"
                        class={`${
                          errors["rCity"]
                            ? "border-red-600 !border-t-red-600"
                            : ""
                        } focus:border-purple-400  peer h-full w-full font-nunito rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3  text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border   focus:border-2  focus:border-t-transparent focus:!border-t-purple-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 `}
                      /> 
                    </div> */}
                    {selectedCountry != "Ethiopia" && (
                      <Select
                        size="lg"
                        selected={(element) =>
                          element &&
                          React.cloneElement(element, {
                            disabled: true,
                            className:
                              "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                          })
                        }
                        value={values["rCity"]}
                        onChange={(e) => handleCity(e)}
                      >
                        {cities.map((city) => (
                          <Option
                            key={city}
                            value={city}
                            className="flex items-center gap-2"
                          >
                            {city}
                          </Option>
                        ))}
                      </Select>
                    )}
                    {selectedCountry == "Ethiopia" && (
                      <span>
                        <b>*We send to all places in Ethiopia</b>
                      </span>
                    )}
                  </div>
                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="md:mt-1 md:p-3 m-auto mb-2">
        <Button
          className=" bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
          onClick={handleNextStep}
          size="sm"
        >
          Continue
        </Button>
      </div>
    </Card>
  );
};
export default RecipientForm;
