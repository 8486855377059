//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
import axiosClient from "./axios-interceptors";
class UserService {
  async addUser(userData) {
    try {
      const response = await axiosClient.post("/addUser", userData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error adding user:", error);
      throw new Error("Failed to add user. Please try again later.");
    }
  }
  async editUser(userData) {
    try {
      const response = await axiosClient.post("/editUser", userData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error editing user:", error);
      throw new Error("Failed to edit user. Please try again later.");
    }
  }
  async deleteUser(data) {
    try {
      const response = await axiosClient.post("/deleteUser", data);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error deleting user:", error);
      throw new Error("Failed to delete user. Please try again later.");
    }
  }

  async getAllUsers() {
    try {
      const response = await axiosClient.get("/getAllUsers");
      return response.data;
    } catch (error) {
      // Log and handle specific error
      console.error("Error fetching all users:", error);
      throw new Error("Failed to fetch all users. Please try again later.");
    }
  }
  async getUserInfo(customerId) {
    try {
      const response = await axiosClient.get("/getUserInfo/" + customerId);
      return response.data;
    } catch (error) {
      // Log and handle specific error
      console.error("Error fetching user info:", error);
      throw new Error("Failed to fetch user info. Please try again later.");
    }
  }
  async filterUsers(filterData) {
    try {
      const response = await axiosClient.post("/filterUsers", filterData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error filtering user:", error);
      throw new Error("Failed to filter users. Please try again later.");
    }
  }
  async updateUserDetails(userData) {
    try {
      const response = await axiosClient.post("/updateUserDetails", userData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error updating user:", error);
      throw new Error("Failed to update user. Please try again later.");
    }
  }
  async forgotPasswordUpdate(userData) {
    try {
      const response = await axiosClient.post(
        "/forgotPasswordUpdate",
        userData
      );
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error updating password:", error);
      throw new Error("Failed to update password. Please try again later.");
    }
  }
  async sendVerificationCode(userData) {
    try {
      const response = await axiosClient.post(
        "/sendVerificationCode",
        userData
      );
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error sending verification code:", error);
      throw new Error(
        "Failed to send verification code. Please try again later."
      );
    }
  }
  async sendContactUsMessage(contactUsData) {
    try {
      const response = await axiosClient.post(
        "/sendContactUsMessage",
        contactUsData
      );
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error sending contact us message:", error);
      throw new Error(
        "Failed to send contact us message. Please try again later."
      );
    }
  }
  async userExist(userData) {
    try {
      const response = await axiosClient.post("/userExist", userData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error finding user exist:", error);
      throw new Error("Failed to find user exist. Please try again later.");
    }
  }
}

export default new UserService();
