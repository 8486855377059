import React, { useState, useEffect } from "react";
import { formatPhone } from "../../utils/helpers";
import {
  List,
  ListItem,
  ListItemPrefix,
  Avatar,
  Card,
  Dialog,
  Button,
  Typography,
} from "@material-tailwind/react";
import BackButton from "../layout/BackButton";

const Summary = ({ recipientData, amtData, onSummaryNext, handlePrev }) => {
  const summaryData = { ...recipientData, ...amtData };
  return (
    <Card className="w-full max-w-[400px] md:max-w-[36rem] mt-40 m-auto">
      <BackButton handlePrev={handlePrev} />
      <div className="w-full md:px-12 px-2">
        <h4 class="block font-nunito ml-[30%] text-[18px] antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 m-auto mb-2">
          Summary
        </h4>
        <List className="font-nunito">
          <ListItem className="flex  justify-between items-center p-1">
            <Typography
              variant="h6"
              className="font-nunito basis-1/4 "
              color="blue-gray"
            >
              Name
            </Typography>
            <div className="basis-2/4">{summaryData.rName}</div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <Typography
              variant="h6"
              className="font-nunito basis-1/4 "
              color="blue-gray"
            >
              You send
            </Typography>
            <div className="basis-2/4">{summaryData.sAmount} CAD</div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <Typography
              variant="h6"
              className="font-nunito basis-1/4 "
              color="blue-gray"
            >
              Recipient gets
            </Typography>
            <div className="basis-2/4">
              {summaryData.rAmount.toLocaleString()} {summaryData.rCurrency}
            </div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <Typography
              variant="h6"
              className="font-nunito basis-1/4 "
              color="blue-gray"
            >
              Phone
            </Typography>
            <div className="basis-2/4">
              {formatPhone(summaryData.rPhoneNumber, summaryData.rCountry)}
            </div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <Typography
              variant="h6"
              className="font-nunito basis-1/4 "
              color="blue-gray"
            >
              City
            </Typography>
            <div className="basis-2/4">{summaryData.rCity}</div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <Typography
              variant="h6"
              className="font-nunito basis-1/4 "
              color="blue-gray"
            >
              Total you pay
            </Typography>
            <div className="basis-2/4">{summaryData.totalPay} CAD</div>
          </ListItem>
          <hr></hr>
        </List>
      </div>
      <div className="md:mt-1 md:p-3 m-auto mb-2">
        <Button
          className=" bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
          onClick={onSummaryNext}
          size="sm"
        >
          Continue
        </Button>
      </div>
    </Card>
  );
};
export default Summary;
