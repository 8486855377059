import axios from "axios";
import authHeader from "./auth-header";
// Create the Axios instance
const axiosClient = axios.create({
  baseURL: "/api",
  headers: authHeader(),
});
let isRedirecting = false;
let idleTimeout;
const idleTimeLimit = 15 * 60 * 1000; // 15 minutes

const resetIdleTimer = () => {
  clearTimeout(idleTimeout);
  idleTimeout = setTimeout(logout, idleTimeLimit);
};
const logout = () => {
  if (!isRedirecting) {
    isRedirecting = true;
    localStorage.removeItem("token");
    window.location.href = "/login";
  }
};

// Add event listeners to reset the idle timer on user activity
const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const debouncedResetIdleTimer = debounce(resetIdleTimer, 300);

export const setupEventListeners = () => {
  const token = localStorage.getItem("token");
  if (token) {
    window.addEventListener("load", debouncedResetIdleTimer);
    document.addEventListener("mousemove", debouncedResetIdleTimer);
    document.addEventListener("keypress", debouncedResetIdleTimer);
    document.addEventListener("click", debouncedResetIdleTimer);
    document.addEventListener("scroll", debouncedResetIdleTimer);
  }
};
export const removeEventListeners = () => {
  window.removeEventListener("load", debouncedResetIdleTimer);
  document.removeEventListener("mousemove", debouncedResetIdleTimer);
  document.removeEventListener("keypress", debouncedResetIdleTimer);
  document.removeEventListener("click", debouncedResetIdleTimer);
  document.removeEventListener("scroll", debouncedResetIdleTimer);
};
// Add a response interceptor
axiosClient.interceptors.request.use(
  (config) => {
    // Modify the request config before sending the request
    config.headers = {
      ...config.headers,
      ...authHeader(), // Include auth headers
    };
    return config;
  },
  (error) => {
    // Handle the request error
    return Promise.reject(error);
  }
);
axiosClient.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx will cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx will cause this function to trigger
    if (error.response && error.response.status === 401 && !isRedirecting) {
      // Redirect to the login page
      isRedirecting = true;
      localStorage.removeItem("token");
      window.location.href = "/login";
    } /* else if (error.response.status === 502 || error.response.status === 504) {
      window.location.href = "/404";
    } */
    return Promise.reject(error);
  }
);
setupEventListeners();
export default axiosClient;
