import { Link } from "react-router-dom";

//import TransferForm from "../TransferForm";
import Container from "../../utils/container";
import { checkUser } from "../../utils/helper";
import TransferForm from "../pages/TransferForm";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className="w-full overflow-hidden bg-[#f2f3f9] wow fadeInUp">
      <div
        className="relative bg-auto bg-no-repeat bg-[top_120px_right] md:bg-[top_70px_right] lg:bg-[top_100px_right]"
        style={{ backgroundImage: "url('/images/banner-map.png')" }}
      >
        <div>
          <img
            className="w-[25%] lg:w-[20%] xl:max-w-full h-auto align-middle absolute left-0 bottom-[5%] animate-move-diagonaLR"
            src="/images/banner-box.png"
            alt=""
          />
          <img
            className="hidden md:block w-[25%] lg:w-[15%] xl:w-[19%] h-auto align-middle absolute top-0 left-0"
            src="/images/banner-clock.png"
            alt=""
          />
          <img
            className="w-[10%] xl:w-[13%] h-auto align-middle absolute left-[calc(48%)] bottom-[12%] animate-move-diagonaRL"
            src="/images/banner-rocket.png"
            alt=""
          />
          <img
            className="block w-[10%] xl:w-[15.5%] h-auto align-middle absolute right-0 bottom-[calc(5%)] animate-move-diagonaLR"
            src="/images/banner-human.png"
            alt=""
          />
        </div>

        <div className="md:pt-[50px] pt-[10px] md:pb-[250px] pb-[140px] xl:pb-[200px]">
          <Container className="md:pt-5 pt-0 flex flex-col md:flex-row md:items-center space-y-6 md:space-y-0 md:space-x-4 lg:space-x-0 lg:justify-between relative z-10">
            <img
              src="/images/banner-wallet.png"
              className="hidden lg:block lg:w-[11%] absolute top-[3%] left-[47%] xl:left-[54%] animate-move-diagonaRL"
              alt=""
            />
            <div>
              {/* <h5 className="font-bold lg:text-xl xl:text-2xl text-indigo-700 mb-3">
                Trusted by over 3M customers
              </h5> */}
              <h1 className="hidden md:block text-5xl lg:text-6xl xl:text-7xl font-bold text-purple-600">
                {t("quick")}, {t("secure")}
                <br /> {t("global")}
              </h1>
              {/* <h1 className="text-xl md:hidden font-bold text-purple-600">
                Quick - Secure - Global
              </h1> */}
              <p className="lg:text-xl xl:text-2xl text-indigo-900 md:my-8 my-2">
                {t("homeInfo")}
              </p>

              <div className="hidden md:flex items-center space-x-4">
                {!checkUser() && (
                  <Link
                    to="/register"
                    className="lg:text-lg truncate transition-all duration-300 px-3 lg:px-4 xl:px-8 font-medium md:py-3 py-2 bg-purple-600 text-white rounded-md focus:outline-none hover:bg-indigo-700 focus:ring focus:border-indigo-500 focus:ring-indigo-500/50"
                  >
                    {t("openFreeAccount")}
                  </Link>
                )}
              </div>
            </div>

            <div className="bg-white border  border-gray-100 rounded-lg shadow-xl p-1 md:p-5 w-full md:w-1/2 lg:w-[40%] xl:w-[33%]">
              <TransferForm type="homePage" amtData={""} />
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default Banner;
