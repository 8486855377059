import axios from "axios";
import configs from "../configs/local";
import axiosClient from "./axios-interceptors";
import {
  setupEventListeners,
  removeEventListeners,
} from "./axios-interceptors";
class AuthService {
  async login(email, password) {
    /* return axios
      .post("/api/auth/signin", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          return response.data;
        } else {
          return { msg: "error" };
        }
      }); */
    try {
      const response = await axiosClient.post("/auth/signin", {
        email,
        password,
      });
      localStorage.setItem("token", response.data.token);
      setupEventListeners();
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error logging in:", error);
      throw new Error("Failed to log in. Please try again later.");
    }
  }
  async logOut() {
    try {
      removeEventListeners(); // Remove event listeners on logout
      localStorage.removeItem("token");
      window.location.href = "/";
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error logging out:", error);
      throw new Error("Failed to log out. Please try again later.");
    }
  }

  async getCurrentUser() {
    /* return axios
      .post("/api/auth/getCurrentUser", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.role) {
            return response.data;
          } else {
            // Handle the case where the response does not contain a role
            return { msg: "error" };
          }
        } else {
          return { msg: "error" };
        }
      })
      .catch((err) => {
        return { msg: "error" };
      }); */
    try {
      const response = await axiosClient.post("/auth/getCurrentUser", {
        token: localStorage.getItem("token"),
      });
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error getting current user:", error);
      throw new Error("Failed to get current user. Please try again later.");
    }
  }
}

export default new AuthService();
