import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Dialog,
  Button,
  Alert,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  PowerIcon,
  PaperAirplaneIcon,
  ArchiveBoxIcon,
  CircleStackIcon,
  BanknotesIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";
import AuthService from "../../services/auth.service";
import TransferService from "../../services/transfer.service";
import { getIpAddress } from "../../utils/helpers";
import Account from "./Account";
import Settings from "./Settings";
//import PayOut from "./PayOut";
import Send from "./Send";
import Transfers from "./Transfers";
import AddressInfoEditorForm from "../editor-forms/AddressInfoEditorForm";
import { roles } from "../../utils/constants";
import HelloPage from "./HelloPage";
import { useNavigate } from "react-router-dom";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
const Dashboard = () => {
  const [loggedInUser, setLoggedInUser] = useState({});
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [notCompletePayments, setNotCompletePayments] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [ipAddress, setIpAddress] = useState("");
  const LogOut = () => {
    AuthService.logOut();
  };
  const startSendingNow = () => {
    setSelectedItem("Send");
  };
  const handleCancel = () => {
    setSelectedItem("Transfers");
  };
  let menuItems = [
    {
      id: "Send",
      label: "Send",
      component: (
        <Send
          handleCancel={handleCancel}
          loggedInUser={loggedInUser}
          ipAddress={ipAddress}
        />
      ),
    },
    {
      id: "Transfers",
      label: "Transfers",
      component: (
        <Transfers
          loggedInUser={loggedInUser}
          startSending={startSendingNow}
          selectedItem={selectedItem}
        />
      ),
    },
    {
      id: "Account",
      label: "Account",
      component: <Account />,
    },
    {
      id: "Settings",
      label: "Settings",
      component: <Settings loggedInUser={loggedInUser} />,
    },
    /*  {
      id: "PayOut",
      label: "PayOut",
      component: (
        <PayOut
          loggedInUser={loggedInUser}
          startSending={startSendingNow}
          selectedItem={selectedItem}
          ipAddress={ipAddress}
        />
      ),
    }, */
    { id: "LogOut", label: "Log Out", component: <></> },
  ];
  const isValidPage = (page) => {
    if (page == "Settings") {
      if (loggedInUser.role == roles.ADMIN) return true;
      else return false;
    }
    if (page == "PayOut") {
      if (
        loggedInUser.role == roles.ADMIN ||
        loggedInUser.role == roles.TESTING
      )
        return true;
      else return false;
    } else return true;
  };
  useEffect(() => {
    (async () => {
      try {
        let loggedInUser = await AuthService.getCurrentUser();
        const ip = await getIpAddress();
        let incompletePayments = await TransferService.getNotCompletedPayments({
          userId: loggedInUser.id,
        });
        if (!loggedInUser) navigate("/login");
        setIpAddress(ip);
        setNotCompletePayments(incompletePayments);
        setLoggedInUser(loggedInUser);
        if (!loggedInUser.province) setOpenDialog(true);
      } catch {
        navigate("/login");
      }
    })();
  }, []);
  return (
    <div>
      {!loggedInUser.province && (
        <Dialog
          size="xs"
          open={openDialog}
          className="bg-transparent shadow-none"
          dismiss={{
            escapeKey: false,
            referencePress: false,
            outsidePress: false,
            ancestorScroll: false,
            bubbles: false,
          }}
        >
          <AddressInfoEditorForm
            loggedInUser={loggedInUser}
            onComplete={() => setOpenDialog(false)}
            type="add"
          />
        </Dialog>
      )}
      <div className="flex h-screen flex-col md:flex-row md:bg-gray-100">
        <div className="flex-shrink-0 md:w-64 bg-gray-800 text-white order-last md:order-first sticky bottom-0 ">
          <Card className=" md:h-[calc(100vh-0rem)] w-full md:max-w-[20rem]  p-0 md:p-4 shadow-xl  md:shadow-blue-gray-900/5 rounded-none">
            <div className="mb-14 p-4 mt-3 hidden md:block">
              <Typography
                variant="h5"
                color="blue-gray"
                className="font-nunito text-[14px] flex flex-col items-center gap-3 mb-2"
              >
                <div className="hidden md:block text-[13px] font-extrabold md:text-[20px] text-white bg-purple-600 md:py-4 md:px-3 px-1 py-2 rounded-xl">
                  AMS
                </div>
                {loggedInUser.firstName} {loggedInUser.lastName}
              </Typography>
              <hr></hr>
            </div>

            <List className=" flex flex-row md:flex-col font-nunito md:mt-10 bg-gray-100 md:bg-transparent">
              {menuItems.map(
                (item) =>
                  isValidPage(item.id) && (
                    <ListItem
                      key={item.id}
                      onClick={() =>
                        item.id == "LogOut"
                          ? LogOut()
                          : setSelectedItem(item.id)
                      }
                      className={`md:mb-3 mb-0 p-0 md:p-3 text-[13px] md:text-sm ${
                        selectedItem == item.id
                          ? " text-purple-600  font-bold md:border-l-4 md:border-b-0 border-b-2 border-purple-600 bg-[#f3f4f6] "
                          : ""
                      }  hover:bg-green-200 hover:text-white focus:text-purple-600 rounded-sm`}
                    >
                      <div className="flex flex-col md:flex-row m-auto md:m-0 md:gap-2 gap-1  mt-1">
                        <ListItemPrefix className="mr-0 md:mr-4">
                          {item.id == "Account" ? (
                            <UserCircleIcon className="h-5 w-5 " />
                          ) : item.id == "LogOut" ? (
                            <PowerIcon className="h-5 w-5 " />
                          ) : item.id == "Send" ? (
                            <RocketLaunchIcon className="h-5 w-5" />
                          ) : item.id == "Settings" ? (
                            <CircleStackIcon className="h-5 w-5" />
                          ) : (
                            <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                          )}
                        </ListItemPrefix>
                        {item.label}
                      </div>
                    </ListItem>
                  )
              )}
            </List>
          </Card>
        </div>
        <div className="flex-1 overflow-x-hidden overflow-y-auto md:p-4">
          {notCompletePayments.length > 0 && open && (
            <Alert
              color="amber"
              //icon={<Icon />}
              className="border-l-4 md:font-medium text-[14px]  px-1 py-1 md:px-4 md:py-4 md:max-w-[70%]"
              AlertProps={{
                className: " mr-0",
              }}
              onClose={() => setOpen(false)}
            >
              <b>Reminder:</b> The payment for transfers{" "}
              {notCompletePayments.map((x, index) => {
                return (
                  <span className="font-bold">
                    {x.ticketNo}
                    {index == notCompletePayments.length - 1 ? " " : ", "}
                  </span>
                );
              })}
              is not completed
            </Alert>
          )}
          {selectedItem &&
            menuItems.map((item) => (
              <div
                key={item.id}
                id={item.id}
                className={`content ${
                  selectedItem === item.id ? "" : "hidden"
                }`}
              >
                {item.component}
              </div>
            ))}
          {!selectedItem && (
            <HelloPage
              startSending={startSendingNow}
              loggedInUser={loggedInUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
