import React, { useState } from "react";
import { StickyNavbar } from "../layout/StickyNavBar";
import { Link, useNavigate } from "react-router-dom";
import {
  isValidEmail,
  isValidPassword,
  isValidCode,
} from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { emailTypes } from "../../utils/constants";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import { Input } from "@material-tailwind/react";
import { Dialog, Card } from "@material-tailwind/react";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [sentCode, setSentCode] = useState("");
  const [values, setValues] = useState({
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
  });
  let [errors, setErrors] = useState({
    verificationCode: 0,
    newPassword: 0,
    confirmPassword: 0,
  });
  const inputs = [
    {
      id: 1,
      name: "verificationCode",
      type: "number",
      placeholder: "verification code",
      errorMessage: "Email address is invalid!",
      label: "Verification Code",
      required: true,
    },
    {
      id: 2,
      name: "newPassword",
      type: "password",
      placeholder: "Password",
      errorMessage: "Password should be 8-20 characters!",
      label: "New Password (at least 8 characters)",
      pattern: `^[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 3,
      name: "confirmPassword",
      type: "password",
      placeholder: "Password",
      errorMessage: "Password should be 8-20 characters!",
      label: "Confirm Password (at least 8 characters)",
      pattern: `^[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleValidation = (e) => {
    if (e.target.name == "verificationCode") {
      !isValidCode(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    }
    if (e.target.name == "newPassword")
      !isValidPassword(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "confirmPassword")
      !isValidPassword(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
  };
  const resetPassword = () => {
    if (
      Object.values(errors).every((v) => v === 0) &&
      !Object.values(values).some((x) => x == "")
    ) {
      if (values["confirmPassword"] === values["newPassword"]) {
        if (values["verificationCode"] === sentCode.toString()) {
          //save
          setMessage("Changing password...");
          setLoading(true);
          UserService.forgotPasswordUpdate({
            password: values["confirmPassword"],
            email: email,
          }).then(() => {
            setTimeout(() => {
              setMessage("Redirecting to login...");
              navigate("/login");
            }, 1000);
          });
        } else {
          setAlert("fail");
          setAlertMsg("Incorrect code!");
        }
      } else {
        setAlert("fail");
        setAlertMsg("Password don't match!");
      }
    } else {
      setAlert("fail");
      setAlertMsg("Incomplete information!");
    }
  };
  const sendCode = () => {
    if (!!email && isValidEmail(email)) {
      setLoading(true);
      setMessage("Sending code...");

      UserService.sendVerificationCode({
        email: email,
        emailType: emailTypes.FORGOT_PASSWORD,
      })
        .then((res) => {
          setSentCode(res.code);
          setStepOne(false);
          setStepTwo(true);
          setLoading(false);
          setAlert("");
        })
        .catch((err) => {
          setAlertMsg("Email not found!");
          setAlert("fail");
          setLoading(false);
          setEmail("");
        });
    } else {
      setAlertMsg("Invalid Email");
      setAlert("fail");
    }
  };
  return (
    <div className="flex flex-col h-[100%]">
      <div className="sticky top-0 z-50 basis-1/6">
        <StickyNavbar pageType={"forgotPassword"} />
      </div>
      <div class="md:mx-auto basis-3/4 max-w-screen-md max-h py-12">
        {!loading && (
          <div class="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto md:max-w-[70%] max-w-[90%]">
            <h4 class="block font-nunito md:text-2xl text-xl antialiased font-semibold  leading-snug tracking-normal mt-12 text-blue-gray-900">
              Forgot your password?
            </h4>
            {stepOne && (
              <p class="block mt-4  text-base antialiased font-normal leading-relaxed  font-nunito text-gray-700">
                Enter the email address you signed up with. We will send you a
                code to reset your password.
              </p>
            )}
            {stepTwo && (
              <p class="block mt-4  text-base antialiased font-normal leading-relaxed  font-nunito text-gray-700">
                We have sent a verification code to your email. Enter the code.
              </p>
            )}
            <form class="max-w-screen-lg mt-8 mb-2 w-80 md:w-96 flex flex-col gap-6">
              {alert && <AlertCustomStyles status={alert} message={alertMsg} />}
              <div class=" mb-1">
                <>
                  {/* <h6 class="block -mb-3 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                    Email
                  </h6> */}
                  <div class=" flex flex-col relative h-11 mb-5 w-full md:min-w-[200px]">
                    <Input
                      label="Email"
                      type="email"
                      disabled={stepTwo}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      containerProps={{
                        className: "md:min-h-[50px] min-h-[50px] text-[20px]",
                      }}
                      size="lg"
                      labelProps={{
                        className: "text-[16px]",
                      }}
                      name="email"
                      class="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3  text-sm font-normal font-nunito text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    />
                    {emailError && (
                      <span class="text-[12px] ml-2 text-[#e53935] ">
                        Invalid email
                      </span>
                    )}
                  </div>
                </>
              </div>
              {stepOne && (
                <button
                  class="mt-6 block w-full select-none rounded-lg  bg-[#882699] hover:bg-[#cf3be9] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md font-nunito shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  disabled={!isValidEmail(email)}
                  onClick={sendCode}
                >
                  Continue
                </button>
              )}
              {stepTwo &&
                inputs.map((input) => (
                  <div class="relative h-11 w-full md:min-w-[200px]">
                    <Input
                      label={input.label}
                      type={
                        input.name == "newPassword" ||
                        input.name == "confirmPassword"
                          ? "password"
                          : "text"
                      }
                      onChange={onChange}
                      value={values[input.name]}
                      onBlur={handleValidation}
                      name={input.name}
                      disabled={
                        (input.name == "newPassword" ||
                          input.name == "confirmPassword") &&
                        values["verificationCode"].length < 4
                          ? true
                          : false
                      }
                      error={errors[input.name]}
                      containerProps={{
                        className: "md:min-h-[45px] min-h-[20px] text-[16px]",
                      }}
                      size="lg"
                      labelProps={{
                        className: "text-[14px]",
                      }}
                      /*  class="peer h-full w-full font-nunito rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" */
                    />
                  </div>
                ))}
              {stepTwo && (
                <button
                  class="mt-6 block w-full select-none rounded-lg bg-[#882699] hover:bg-[#cf3be9] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md font-nunito shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  disabled={!isValidEmail(email)}
                  onClick={resetPassword}
                >
                  Change Password
                </button>
              )}
            </form>
          </div>
        )}
        {loading && (
          <div className="m-auto">
            <Dialog
              size="sm"
              open={true}
              className="bg-transparent shadow-none opacity-60"
              dismiss={{
                escapeKey: false,
                referencePress: false,
                outsidePress: false,
                ancestorScroll: false,
                bubbles: false,
              }}
            >
              <Card className="mx-auto w-full max-w-[26rem] p-4">
                <SpinnerWithInfo message={message} />
              </Card>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
};
export default ForgotPassword;
