import React, { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
} from "@material-tailwind/react";
import { canadianProvinces } from "../../utils/constants";
import { isValidName, isValidPostalCode } from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import UserService from "../../services/user.service";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
export default function AddressInfoEditorForm({
  loggedInUser,
  onComplete,
  type,
}) {
  const [values, setValues] = useState({
    streetAddress: loggedInUser.streetAddress || "",
    apptNo: loggedInUser.apptNo || "",
    city: loggedInUser.city || "",
    province: loggedInUser.province || "",
    postalCode: loggedInUser.postalCode || "",
    id: loggedInUser.id || "",
  });
  let [errors, setErrors] = useState({
    streetAddress: 0,
    city: 0,
    province: 0,
    postalCode: 0,
  });
  const defaultProvince = loggedInUser.province || "";
  const inputs = [
    {
      id: 1,
      name: "streetAddress",
      type: "streetAddress",
      placeholder: "streetAddress",
      errorMessage: "Street Address is required",
      label: "Street Address",
      required: true,
    },
    {
      id: 2,
      name: "apptNo",
      type: "apptNo",
      placeholder: "apptNo",
      errorMessage: "",
      label: "Apartment Number",
      required: false,
    },
    {
      id: 3,
      name: "city",
      type: "city",
      placeholder: "city",
      errorMessage: "city is required",
      label: "City",
      required: true,
    },
    {
      id: 4,
      name: "province",
      type: "province",
      placeholder: "province",
      errorMessage: "",
      label: "Province",
      required: true,
    },

    {
      id: 5,
      name: "postalCode",
      type: "postalCode",
      placeholder: "postalCode",
      errorMessage: "postal Code is required",
      label: "Postal Code",
      required: true,
    },
  ];
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onChangeSelect = (e) => {
    setValues({ ...values, province: e });
    setErrors({ ...errors, province: 0 });
  };
  const handleValidation = (e) => {
    if (e.target.name != "postalCode") {
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    } else {
      !isValidPostalCode(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    }
  };
  const handleSubmit = () => {
    //remove apptNo from empty check
    const valuesCheck = { ...values };
    delete valuesCheck.apptNo;
    if (
      Object.values(valuesCheck).some((v) => v == "") ||
      !Object.values(errors).every((v) => v === 0)
    ) {
      if (!values["province"]) setErrors({ ...errors, province: 1 });
      setAlert("NotComplete");
    } else {
      setAlert("");
      setLoading(true);
      UserService.updateUserDetails(values).then(
        () => {
          setLoading(false);
          type == "add"
            ? onComplete()
            : onComplete({ type: "Address", result: "success" });
        },
        (error) => {
          setLoading(false);
          type == "add"
            ? setAlert("fail")
            : onComplete({ type: "Address", result: "fail" });
          //setValues({ Email: "", Password: "" });
        }
      );
    }
  };
  return (
    <>
      <Card className="mx-auto w-full max-w-[24rem]">
        {!loading && (
          <>
            <CardBody className="flex flex-col gap-6">
              {alert && alert == "fail" && (
                <AlertCustomStyles
                  status={alert}
                  message={"Updating address failed, try again!"}
                />
              )}
              {alert && alert == "NotComplete" && (
                <AlertCustomStyles
                  status={alert}
                  message={"Complete form and try again!"}
                />
              )}
              {type == "add" && (
                <Typography
                  variant="h4"
                  color="blue-gray"
                  className="font-nunito"
                >
                  Welcome, {loggedInUser.name}
                </Typography>
              )}
              <Typography
                className="mb-3 font-normal"
                variant="paragraph"
                color="gray"
              >
                Please fill in your address details.
              </Typography>
              {inputs.map((input) => (
                <>
                  {input.label != "Province" &&
                    input.label != "Apartment Number" && (
                      <Input
                        label={input.label}
                        name={input.name}
                        required
                        onChange={onChange}
                        onBlur={handleValidation}
                        error={errors[input.name]}
                        value={values[input.name]}
                      />
                    )}
                  {input.label == "Apartment Number" && (
                    <Input
                      label={input.label}
                      name={input.name}
                      onChange={onChange}
                      value={values[input.name]}
                    />
                  )}
                  {input.label == "Province" && (
                    <Select
                      onChange={onChangeSelect}
                      value={values["province"]}
                      label={"Province"}
                      error={errors["province"]}
                    >
                      {canadianProvinces.map((x) => (
                        <Option value={x}>{x}</Option>
                      ))}
                    </Select>
                  )}
                </>
              ))}
            </CardBody>
            <CardFooter className="pt-0 flex gap-3 m-auto">
              <Button
                variant="button"
                onClick={handleSubmit}
                className="bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
                size="sm"
              >
                Update
              </Button>
              {type == "update" && (
                <Button
                  variant="outlined"
                  color="red"
                  onClick={() => onComplete({ type: "Address", result: null })}
                  size="sm"
                  className="normal-case md:text-[15px] text-[12px]"
                >
                  <span>Cancel</span>
                </Button>
              )}
            </CardFooter>
          </>
        )}
        {loading && (
          <div className="p-20">
            <SpinnerWithInfo message="Saving changes..." />
          </div>
        )}
      </Card>
    </>
  );
}
