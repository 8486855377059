import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemPrefix,
  Avatar,
  Card,
  Dialog,
  Typography,
  Button,
} from "@material-tailwind/react";
import SettingsEditorForm from "../editor-forms/SettingsEditorForm";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import ConversionRateService from "../../services/conversionRate.service";
import { selectedCountries, usdCountries } from "../../utils/constants";
import AuthService from "../../services/auth.service";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const Settings = ({ loggedInUser }) => {
  const [editor, setEditor] = useState("");
  const [alert, setAlert] = useState("");
  const [openD, setOpenD] = useState(false);
  const responseFromDialog = (e) => {};
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [editedCurrency, setEditedCurrency] = useState({});
  const [conversionDetails, setConversionDetails] = useState([]);
  const [index, setIndex] = useState(0);
  const onUpdate = (res) => {
    if (res.result) {
      setAlert(res.result);
      setEditedCurrency(res.currency);
      setConversionDetails(
        conversionDetails.map((conversion, ix) =>
          ix === index
            ? { ...conversion, rate: res.values.rate, fee: res.values.fee }
            : { ...conversion }
        )
      );
    }
    setOpenD(false);
  };
  const openDialog = (country, index) => {
    setIndex(index);
    setOpenD(true);
    setSelectedCountry(country);
  };
  const TABLE_HEAD = ["Conversion", "Rate (CAD)", "Fee (CAD)", "Action"];
  useEffect(() => {
    (async () => {
      let rates = await ConversionRateService.getAllConversionRates();
      rates = rates.filter((x) => !usdCountries.includes(x.country));
      setConversionDetails(rates);
      setLoading(false);
    })();
  }, [alert]);
  return (
    <div class="mx-auto  max-w-[400px] max-h md:py-[8%] font-nunito mt-[2%] md:mt-0">
      {loading && (
        <div className="mt-[20%]">
          <SpinnerWithInfo message="Please wait..." />
        </div>
      )}
      {!loading && (
        <div class="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border m-auto">
          {alert && alert == "fail" && (
            <AlertCustomStyles
              status={alert}
              message={`Updating ${editedCurrency} failed, try again!`}
            />
          )}
          {alert && alert == "success" && (
            <AlertCustomStyles
              status={alert}
              message={`${editedCurrency} updated successfully!`}
            />
          )}
          <table className="w-full min-w-max table-auto text-center">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border border-blue-gray-100 bg-blue-gray-50 md:p-4 p-1"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70 text-[14px] md:text-sm"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {conversionDetails.map((country, index) => {
                return (
                  <tr className="even:bg-blue-gray-50/50">
                    <td className="md:p-3 p-1 border border-blue-gray-100">
                      {country.country}
                      {country.country != "USA" && (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="md:text-[14px] text-[11px] flex"
                        >
                          <span>1 CAD</span>{" "}
                          <span>
                            <ArrowRightIcon className="h-3 w-7 opacity-60 mt-[3px] " />{" "}
                          </span>
                          {country.symbol}
                        </Typography>
                      )}
                      {country.country == "USA" && (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="md:text-[14px] text-[11px] flex"
                        >
                          <span>1 USD</span>{" "}
                          <span>
                            <ArrowRightIcon className="h-3 w-7 opacity-60 mt-[3px] " />{" "}
                          </span>
                          CAD
                        </Typography>
                      )}
                    </td>
                    <td className="p-3 border border-blue-gray-100">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {country.rate}
                      </Typography>
                    </td>
                    <td className="p-3 border border-blue-gray-100">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {country.fee}
                      </Typography>
                    </td>
                    <td className="p-3 border border-blue-gray-100">
                      <Button
                        onClick={() => openDialog(country, index)}
                        className=" bg-[#882699] hover:bg-[#cf3be9] md:text-[15px] normal-case text-[11px]"
                        size="sm"
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <Dialog
        size="xs"
        open={openD}
        handler={responseFromDialog}
        className="bg-transparent shadow-none"
        dismiss={{
          escapeKey: false,
          referencePress: false,
          outsidePress: false,
          ancestorScroll: false,
          bubbles: false,
        }}
      >
        <SettingsEditorForm
          selectedCountry={selectedCountry}
          onComplete={(res) => onUpdate(res)}
          onCancel={() => setOpenD(false)}
          loggedInUser={loggedInUser}
        />
      </Dialog>
    </div>
  );
};
export default Settings;
