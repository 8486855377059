import React, { useEffect, useState, useRef } from "react";
import TransferService from "../../services/transfer.service";
import {
  roles,
  paymentStatuses,
  transferStatuses,
} from "../../utils/constants";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import { formatPhone } from "../../utils/helpers";
import { StickyNavbar } from "../layout/StickyNavBar";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  DialogFooter,
  Input,
  Dialog,
} from "@material-tailwind/react";
import moment from "moment-timezone";
import ShowTransfer from "./ShowTransfer";
const TABLE_HEAD = [
  "Transfer ID",
  "Transfer Status",
  "Payment Status",
  "Recipient Name",
  "Recipient Amount",
  "Country",
  "Recipient Phone",
];
const TABLE_HEAD_EXPAND = [
  "Sender Name",
  "Sender Amount",
  "Total Pay",
  "Date",
  "Sender Phone",
  "Action",
];
const TABLE_HEAD_MOB = [
  "Transfer ID",
  "Name",
  "Transfer Status",
  "Payment Status",
];

export default function Transfers({
  loggedInUser,
  startSending,
  selectedItem,
}) {
  const [transfersData, setTransfersData] = useState([]);
  const [transferDetails, setTransferDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openD, setOpenD] = useState(false);
  const [alert, setAlert] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const handleRowClick = (index) => {
    // Toggle the expanded row, close if it's already open
    setExpandedRow(expandedRow === index ? null : index);
  };
  const responseFromDialog = (e) => {};
  const handleShowTransfer = (row) => {
    setTransferDetails(row);
    setOpenD(true);
  };

  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = transfersData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(transfersData.length / itemsPerPage);
  const [active, setActive] = React.useState(1);
  const [handleCompleteOpen, setHandleCompleteOpen] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => pageNumberClick(index),
  });
  const pageNumberClick = (index) => {
    const newOffset = index * itemsPerPage - itemsPerPage;
    setItemOffset(newOffset);
    setActive(index);
  };
  const next = () => {
    if (active === pageCount) return;
    const newOffset = (active + 1) * itemsPerPage - itemsPerPage;
    setItemOffset(newOffset);
    setActive(active + 1);
  };

  const prev = () => {
    if (active === 1) return;
    const newOffset = (active - 1) * itemsPerPage - itemsPerPage;
    setItemOffset(newOffset);
    setActive(active - 1);
  };
  const handleComplete = (row) => {
    setHandleCompleteOpen(true);
    setSelectedTransfer(row);
  };
  const onYes = () => {
    setIsUpdating(true);
    const updateTransferObj = {
      transfer: selectedTransfer,
    };
    TransferService.completeTransfer(updateTransferObj)
      .then((res) => {
        setHandleCompleteOpen(false);
        setIsUpdating(false);
        // Update the local state with the updated transfer
        setTransfersData((prevData) =>
          prevData.map((transfer) =>
            transfer.id === selectedTransfer.id
              ? {
                  ...transfer,
                  transferStatus: transferStatuses.PAID,
                  transferDeliveryMessageSent: 1,
                  ...res,
                }
              : transfer
          )
        );
        setAlert("success");
      })
      .catch((err) => {
        setHandleCompleteOpen(false);
        setAlert("fail");
      });
  };
  const onNo = () => {
    setHandleCompleteOpen(false);
    //setSelectedTransfer({});
  };
  useEffect(() => {
    const today = [
      {
        date: {
          startDate: moment()
            .subtract(30, "days")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        },
      },
    ];
    setIsLoading(true);
    //setAlert("");
    TransferService.filterTransfers({
      loggedInUser: loggedInUser,
      today: today,
    })
      .then(async (res) => {
        setTransfersData(res);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, [selectedItem]);
  return (
    <div class=" m-auto md:ml-[2%] md:mr-[2%] ml-1 max-h md:py-12">
      {isLoading && (
        <div className="mt-[20%]">
          <SpinnerWithInfo message="Please wait..." />
        </div>
      )}
      {!isLoading && transfersData.length === 0 && (
        <div className="md:mt-[20%] p-4 m-auto mt-[60%]">
          <h1 className="m-auto md:ml-[38%] md:text-[20px] tex-[14px]">
            <b>You have not transferred yet, Click below to begin.</b>
          </h1>
          <button
            type="button"
            size="lg"
            onClick={() => startSending()}
            class="mt-6 block m-auto md:max-w-[40%] select-none rounded-lg bg-[#ab47bc] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          >
            Start Sending Now
          </button>
        </div>
      )}
      {!isLoading && transfersData.length > 0 && (
        <>
          <div className="sticky top-0 z-50 mt-2 flex md:hidden">
            <StickyNavbar pageType={"mobileView"} />
          </div>
          <Card className="h-full w-full mt-4 md:mt-1 rounded-none shadow-none md:rounded-lg md:shadow-sm">
            <CardHeader floated={false} shadow={false} className="rounded-none">
              {alert && alert == "fail" && (
                <AlertCustomStyles
                  status={alert}
                  message={`Updating ${selectedTransfer.ticketNo} failed, try again!`}
                />
              )}
              {alert && alert == "success" && (
                <AlertCustomStyles
                  status={alert}
                  message={`${selectedTransfer.ticketNo} updated successfully!`}
                />
              )}
              <div className="md:mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                <div className="hidden md:block">
                  <Typography variant="h5" color="blue-gray">
                    Transfers
                  </Typography>
                  <Typography color="gray" className="mt-1 font-normal">
                    These are records of your transfers
                  </Typography>
                </div>
                {loggedInUser.role == roles.ADMIN && (
                  <div className="flex w-full shrink-0 gap-2 md:w-max">
                    <div className="w-full md:w-72">
                      <Input
                        label="Search"
                        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                      />
                    </div>
                    <Button className="flex items-center gap-3" size="sm">
                      <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" />{" "}
                      Download
                    </Button>
                  </div>
                )}
              </div>
            </CardHeader>
            <CardBody className=" px-0 hidden md:flex md:flex-col md:items-center">
              <table className="w-full min-w-max table-auto text-left ">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((row, index) => {
                    const isLast = index === transfersData.length - 1;
                    const classes = isLast
                      ? "p-2 "
                      : "p-2 border-b border-blue-gray-50";

                    return (
                      <React.Fragment key={index}>
                        <tr
                          key="row"
                          onClick={() => handleRowClick(index)}
                          className="cursor-pointer"
                        >
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <Avatar
                                src={"./userProfile.svg"}
                                alt="row"
                                size="md"
                                className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                              />

                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-[16px]"
                              >
                                {row.ticketNo}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                size="sm"
                                variant="ghost"
                                value={row.transferStatus}
                                color={
                                  row.transferStatus === transferStatuses.PAID
                                    ? "green"
                                    : row.transferStatus ===
                                      transferStatuses.PENDING
                                    ? "amber"
                                    : row.transferStatus ===
                                      transferStatuses.INITIATED
                                    ? "gray"
                                    : "red"
                                }
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                size="sm"
                                variant="ghost"
                                value={row.paymentStatus}
                                color={
                                  row.paymentStatus ===
                                  paymentStatuses.NOT_COMPLETED
                                    ? "gray"
                                    : row.paymentStatus ===
                                      paymentStatuses.COMPLETED
                                    ? "green"
                                    : "red"
                                }
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className=" text-[16px] "
                              >
                                {row.rName}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="text-[16px]"
                            >
                              {row.rAmount.toLocaleString()} {row.rCurrency}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="text-[16px]"
                            >
                              {row.rCountry}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="text-[16px]"
                            >
                              {formatPhone(row.rPhoneNumber, "")}
                            </Typography>
                          </td>
                        </tr>
                        {expandedRow === index && (
                          <tr>
                            <td colSpan={10} className="p-4 bg-gray-300">
                              <div
                                className={`transition-all duration-1000 ease-in-out overflow-hidden ${
                                  expandedRow === index
                                    ? "max-h-[300px] opacity-100"
                                    : "max-h-0 opacity-0"
                                }`}
                              >
                                <div className="flex gap-8">
                                  <div className=" flex flex-col gap-4 ">
                                    <p>
                                      <strong>Sender Amount:</strong>{" "}
                                      {row.sAmount.toLocaleString()}{" "}
                                      {row.sCurrency}
                                    </p>
                                    <p>
                                      <strong>Total pay:</strong>{" "}
                                      {row.totalPay.toLocaleString()}{" "}
                                      {row.sCurrency}
                                    </p>
                                    {/* Add more expanded details as needed */}
                                  </div>
                                  {loggedInUser.role == roles.ADMIN && (
                                    <>
                                      <div className="flex flex-col gap-4 ">
                                        <p>
                                          <strong>Sender Phone:</strong>{" "}
                                          {formatPhone(row.user.phone, "")}
                                        </p>
                                        <p>
                                          <strong>Sender Email:</strong>{" "}
                                          {row.user.email}
                                        </p>
                                        {/* Add more expanded details as needed */}
                                      </div>

                                      <div className=" flex flex-col gap-4 ">
                                        <p>
                                          <strong> Created Date:</strong>{" "}
                                          {moment(row.createdAt).format(
                                            "MMMM DD, YYYY"
                                          )}
                                        </p>
                                        <p>
                                          <strong>Updated Date:</strong>{" "}
                                          {moment(row.updatedAt).format(
                                            "MMMM DD, YYYY"
                                          )}
                                        </p>

                                        {/* Add more expanded details as needed */}
                                      </div>
                                      <div className=" flex flex-col gap-4 ">
                                        <p>
                                          <strong>Sender Name:</strong>{" "}
                                          {row.user.firstName +
                                            " " +
                                            row.user.lastName}
                                        </p>
                                        {row.transferStatus ==
                                          transferStatuses.PENDING && (
                                          <p className="flex gap-2">
                                            <Chip
                                              size="lg"
                                              variant="ghost"
                                              value="Confirm Delivery"
                                              color="green"
                                              className="cursor-pointer hover:bg-blue-gray-50"
                                              onClick={() =>
                                                handleComplete(row)
                                              }
                                            />
                                          </p>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
              <Dialog
                size="xs"
                open={handleCompleteOpen}
                handler={responseFromDialog}
                dismiss={{
                  escapeKey: false,
                  referencePress: false,
                  outsidePress: false,
                  ancestorScroll: false,
                  bubbles: false,
                }}
              >
                <div className="flex flex-col p-6 items-center gap-3">
                  {!isUpdating && (
                    <>
                      <h2>Are you sure you want to complete?</h2>
                      <div className="flex gap-3">
                        <Button
                          className="  normal-case text-[12px]"
                          onClick={() => onYes()}
                          size="sm"
                          variant="filled"
                          color="indigo"
                        >
                          Yes
                        </Button>
                        <Button
                          className="  normal-case text-[12px]"
                          onClick={() => onNo()}
                          variant="outlined"
                          color="red"
                          size="sm"
                        >
                          No
                        </Button>
                      </div>
                    </>
                  )}
                  {isUpdating && (
                    <div>
                      <SpinnerWithInfo message="Please wait..." />
                    </div>
                  )}
                </div>
              </Dialog>
              {transfersData.length > itemsPerPage && (
                <div className="flex items-center gap-4 mt-6">
                  <Button
                    variant="text"
                    className="flex items-center gap-2"
                    onClick={prev}
                    disabled={active === 1}
                  >
                    <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />{" "}
                    Previous
                  </Button>
                  <div className="flex items-center gap-2">
                    {[...Array(pageCount)].map((elementInArray, index) => (
                      <IconButton {...getItemProps(index + 1)} color={"purple"}>
                        {index + 1}
                      </IconButton>
                    ))}
                  </div>
                  <Button
                    variant="text"
                    className="flex items-center gap-2"
                    onClick={next}
                    disabled={active === pageCount}
                  >
                    Next
                    <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </CardBody>
            <CardBody className="md:p-6 p-4 overflow-scroll px-2 md:hidden">
              <table className="w-full min-w-max table-auto text-left ">
                <thead>
                  <tr>
                    {TABLE_HEAD_MOB.map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-1"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold leading-none opacity-70 text-[12px]"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transfersData.map((row, index) => {
                    const isLast = index === transfersData.length - 1;
                    const classes = isLast
                      ? "md:p-4 px-0 py-2"
                      : "md:p-4 px-0 py-2 border-b border-blue-gray-50";

                    return (
                      <tr key="row" onClick={() => handleShowTransfer(row)}>
                        <td className={`${classes} w-16`}>
                          <div className="flex items-center  gap-3">
                            {/* <Avatar
                            src={"./userProfile.svg"}
                            alt="row"
                            size="md"
                            className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                          /> */}

                            <Typography
                              variant="small"
                              color="blue"
                              className="font-bold text-[12px]"
                            >
                              {row.ticketNo}
                            </Typography>
                          </div>
                        </td>
                        <td className={`${classes} w-24`}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=" text-[14px] break-words"
                            >
                              {row.rName}
                            </Typography>
                          </div>
                        </td>
                        <td className={`${classes} w-16`}>
                          <div className="w-max">
                            <Chip
                              variant="ghost"
                              value={row.transferStatus}
                              className="text-[10px] p-1 rounded"
                              color={
                                row.transferStatus === "Paid"
                                  ? "green"
                                  : row.transferStatus === "Pending"
                                  ? "amber"
                                  : row.transferStatus ===
                                    transferStatuses.INITIATED
                                  ? "gray"
                                  : "red"
                              }
                            />
                          </div>
                        </td>
                        <td className={`${classes} w-16`}>
                          <div className="w-max">
                            <Chip
                              variant="ghost"
                              value={row.paymentStatus}
                              className="text-[10px] p-1 rounded"
                              color={
                                row.paymentStatus === "Not Completed"
                                  ? "gray"
                                  : row.paymentStatus === "Completed"
                                  ? "green"
                                  : "red"
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Dialog
                size="xs"
                open={openD}
                handler={responseFromDialog}
                className="bg-transparent shadow-none"
                dismiss={{
                  escapeKey: false,
                  referencePress: false,
                  outsidePress: false,
                  ancestorScroll: false,
                  bubbles: false,
                }}
              >
                <ShowTransfer
                  transferDetails={transferDetails}
                  onCancel={() => setOpenD(false)}
                />
              </Dialog>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
}
