import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  Card,
  Typography,
  Button,
} from "@material-tailwind/react";

const ShowTransfer = ({ transferDetails, onCancel }) => {
  return (
    <Card className="w-full max-w-[400px] md:max-w-[36rem] mt-40 m-auto">
      <div className="w-full md:px-12 px-2">
        <List className="font-nunito">
          <ListItem className="flex  justify-between items-center p-1">
            <div className="font-semibold">You sent</div>
            <div className="basis-1/2">{transferDetails.sAmount} CAD</div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <div className="font-semibold">Recipient gets</div>
            <div className="basis-1/2">
              {transferDetails.rAmount} {transferDetails.rCurrency}
            </div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <div className="font-semibold">Phone</div>
            <div className="basis-2/4">
              {transferDetails.countryCode}-{transferDetails.rPhoneNumber}
            </div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <div className="font-semibold">City</div>
            <div className="basis-2/4">{transferDetails.rCity}</div>
          </ListItem>
          <hr></hr>
          <ListItem className="flex  justify-between items-center p-1">
            <div className="font-semibold">Total paid</div>
            <div className="basis-2/4">{transferDetails.totalPay} CAD</div>
          </ListItem>
          <hr></hr>
        </List>
      </div>
      <div className="md:mt-1 md:p-3 m-auto mb-2">
        <Button
          className=" bg-[#882699] hover:bg-[#cf3be9] normal-case text-[12px]"
          onClick={() => onCancel()}
          size="sm"
        >
          Close
        </Button>
      </div>
    </Card>
  );
};
export default ShowTransfer;
