import axios from "axios";
export function isValidEmail(email) {
  if (email == null || email == undefined || email == "") return false;
  let regexStr = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  return new RegExp(regexStr).test(email.toLowerCase());
}
export function isValidName(name) {
  if (name == null || name == undefined || name == "") return false;
  let regexStr = "^[a-zA-Z0-9ÀÂÄÆÇÈÉÊËÎÏÔŒÙÛÜàâäæçèéêëîïôœùûü '.-]+$";
  return new RegExp(regexStr).test(name);
}
export function isValidMessage(message) {
  if (message == null || message == undefined || message == "") return false;
  return true;
}
export function isValidAmount(amt) {
  if (
    amt == null ||
    amt == undefined ||
    amt == "" ||
    parseInt(amt) == 0 ||
    parseInt(amt) < 50 ||
    parseInt(amt) > 2999
  )
    return false;
  return true;
}
export function isValidPassword(pwd) {
  if (pwd == null || pwd == undefined || pwd == "") return false;
  let regexStr = "^[a-zA-Z0-9!@#$%^&*]{8,20}$";
  return new RegExp(regexStr).test(pwd);
}
export function isValidCode(code) {
  if (code == null || code == undefined || code == "" || code.length != 4)
    return false;
  return true;
}
export function isValidPhoneNumber(Phone) {
  let phone = Phone?.replace(/[- )(]/g, "");
  if (
    phone == null ||
    phone == undefined ||
    phone == "" ||
    phone.length < 7 ||
    phone.length > 11
  )
    return false;
  return true;
}
export function isPayOutValidPhoneNumber(Phone) {
  let phone = Phone?.replace(/[- )(]/g, "");
  if (phone == null || phone == undefined || phone == "" || phone.length != 10)
    return false;
  return true;
}
export function getCities(country) {
  let cities = [];
  if (country == "Eritrea")
    cities = [
      "Asmara",
      "Senafe",
      "Adi Qeyih",
      "Dekemhare",
      "Mendefera",
      "Adi Kuala",
      "Barentu",
      "Tesseney",
    ];
  if (country == "Ethiopia") cities = ["Any city"];
  if (country == "Uganda") cities = ["Kampala"];
  if (country == "Sudan") cities = ["Kassala", "Gedaref"];
  if (country == "Kenya") cities = ["Nairobi"];
  return cities;
}
export function isValidPostalCode(postalCode) {
  if (
    postalCode == null ||
    postalCode == undefined ||
    postalCode == "" ||
    postalCode.replaceAll(" ", "").length != 6
  )
    return false;
  var postalCodeRegex = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;

  if (postalCodeRegex.test(postalCode.replaceAll(" ", ""))) {
    return true;
  } else {
    return false;
  }
}
export function isValidCvc(cvc) {
  if (
    cvc == null ||
    cvc == undefined ||
    cvc == "" ||
    cvc.replaceAll(" ", "").length < 3
  )
    return false;
  return true;
}
export function isValidCardNumber(cardNumber) {
  if (
    cardNumber == null ||
    cardNumber == undefined ||
    cardNumber == "" ||
    cardNumber.replaceAll(" ", "").length < 16
  )
    return false;
  return true;
}
export function isValidExpires(number) {
  if (
    number == null ||
    number == undefined ||
    number == "" ||
    number.replaceAll(" ", "").length < 4
  )
    return false;
  return true;
}
export function getYears() {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 18;
  const years = Array.from({ length: 118 }, (_, i) => startYear - i);
  return years;
}
export function formatPhone(phone, country) {
  var phoneRegex = /^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  var phoneRegexCanada = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  var phoneRegexEri = /^\(?([0-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
  var phoneRegexEthio = /^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  var phoneRegexSud = /^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (country == "Eritrea") {
    return phone.replace(phoneRegexEri, "($1) $2-$3");
  }
  if (country == "Ethiopia") {
    return phone.replace(phoneRegexEthio, "($1) $2-$3");
  }
  if (country == "Sudan") {
    return phone.replace(phoneRegexSud, "($1) $2-$3");
  }
  if (country == "Canada") {
    return phone.replace(phoneRegexCanada, "($1) $2-$3");
  } else return phone.replace(phoneRegex, "($1) $2-$3");
}
export function getDays() {
  return Array.from({ length: 31 }, (_, i) => i + 1);
}
export async function getIpAddress() {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    console.error("Error fetching the IP address:", error);
    return null;
  }
}
