import env from "../utils/env";
const config = {
  APP_NAME: env("NAME"),
  USER_NAME: "d6c45692-62d9-49b1-80be-b45757a93d9f",
  PASSWORD: "142c4f9b-07a0-47f9-aacd-1f1af8dd3e12",
  AUTH: {
    DRIVER: localStorage,
    TYPE: "LARAVEL_PASSPORT",
    REDIRECT_LOGIN: "/dashboard",
    REDIRECT_LOGOUT: "/login",
    API_HOST: env("API_HOST"),
  },
};

export default config;
