import React, { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
} from "@material-tailwind/react";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import ConversionRateService from "../../services/conversionRate.service";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
export default function SettingsEditorForm({
  selectedCountry,
  onComplete,
  onCancel,
  loggedInUser,
}) {
  const [values, setValues] = useState({
    rate: selectedCountry?.rate || "",
    fee: selectedCountry?.fee || "",
    country: selectedCountry?.country || "",
    originalFee: selectedCountry?.fee || "",
    originalRate: selectedCountry?.rate || "",
    user: loggedInUser || "",
  });
  let [errors, setErrors] = useState({
    rate: 0,
    fee: 0,
  });
  const inputs = [
    {
      id: 1,
      name: "rate",
      type: "Rate",
      placeholder: "Rate",
      label: "Rate",
      required: true,
    },
    {
      id: 2,
      name: "fee",
      type: "Fee",
      placeholder: "Fee",
      label: "Fee",
      required: true,
    },
  ];
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleValidation = (e) => {
    e.target.value == ""
      ? setErrors({ ...errors, [e.target.name]: 1 })
      : setErrors({ ...errors, [e.target.name]: 0 });
  };
  const handleSubmit = () => {
    if (
      Object.values(values).some((v) => v == "") ||
      !Object.values(errors).every((v) => v === 0)
    ) {
      setAlert("NotComplete");
    } else {
      setAlert("");
      setLoading(true);
      ConversionRateService.editConversionRate(values).then(
        () => {
          setLoading(false);
          onComplete({
            currency: selectedCountry.currency,
            values: values,
            result: "success",
          });
        },
        (error) => {
          setLoading(false);
          onComplete({
            currency: selectedCountry.currency,
            result: "fail",
          });
        }
      );
    }
  };
  return (
    <>
      <Card className="mx-auto w-full max-w-[24rem]">
        {!loading && (
          <>
            <CardBody className="flex flex-col gap-6">
              {alert && alert == "fail" && (
                <AlertCustomStyles
                  status={alert}
                  message={"Updating address failed, try again!"}
                />
              )}
              {alert && alert == "NotComplete" && (
                <AlertCustomStyles
                  status={alert}
                  message={"Complete form and try again!"}
                />
              )}
              <Typography
                className="mb-3 font-normal"
                variant="paragraph"
                color="gray"
              >
                {selectedCountry.currency}
              </Typography>
              {inputs.map((input) => (
                <Input
                  label={input.label}
                  name={input.name}
                  required
                  onChange={onChange}
                  onBlur={handleValidation}
                  error={errors[input.name]}
                  value={values[input.name]}
                />
              ))}
            </CardBody>
            <CardFooter className="pt-0 flex gap-3 m-auto">
              <Button
                variant="button"
                onClick={handleSubmit}
                className="bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
                size="sm"
              >
                Update
              </Button>
              <Button
                variant="outlined"
                color="red"
                onClick={() => onCancel()}
                size="sm"
                className="normal-case md:text-[15px] text-[12px]"
              >
                <span>Cancel</span>
              </Button>
            </CardFooter>
          </>
        )}
        {loading && (
          <div className="p-20">
            <SpinnerWithInfo message="Saving changes..." />
          </div>
        )}
      </Card>
    </>
  );
}
